import React from "react";

const MessageCount = ({ msg }) => {
  return (
    <div className="form-group float-end m-2 fs-5 ">
      <span className="label label-primary text-white bg-primary p-2">
        عدد الحروف {msg.length} - عدد الرسائل{" "}
        {msg.length < 71 ? 1 : Math.ceil(msg.length / 63)}
      </span>
    </div>
  );
};

export default MessageCount;
