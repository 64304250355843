import axios from "axios";
import logger from "./logService";
import { toast } from "react-toastify";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    logger.log(error);
    toast.error("An unexpected error occurrred.", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }
  try {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("userName");

      toast.error(
        "حدث خطأ بالصلاحية ربما منتهية يرجوى حفظ عملك والخروج والدخول من جديد",
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
      window.location = "/login";
    }
  } catch (ex) {}

  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};
