import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUserList } from "../../store/user";
import Select from "./select";

function UserFilter({ handleFilter, filterUser }) {
  const dispatch = useDispatch();
  const users = useSelector(getUserList);
  //   const handleChange = (event) => {
  //     const userlocked = event.target.value;
  //   };
  return (
    <div className="m-2">
      <select
        onChange={(e) => handleFilter(e.target.value)}
        name="userfilter"
        id="userfilter"
        className="m-1"
      >
        <option value="" key="0">
       بحسب المفسر
        </option>
        {users.map((user, index) => (
          <option key={"user" + index} value={user}>
            {user}
          </option>
        ))}
      </select>
    </div>
  );
}

export default UserFilter;
