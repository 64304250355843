import React, { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  EditMsg,
  SendMsg,
  getLoadCurrentMsgs,
  LoadMsgsist,
  LoadSentDeleteDreams,
  getLoadDreams_Sent_Delete,
  FilterListChange,
} from "../store/messages/messages";
import { loaduserlistfromDB, userAuth } from "../store/user";
import Pagination from "../utils/pagination";
import _ from "lodash";
import AuditorDataTable from "./auditorDataTable";
import Nav from "./nav";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Mobile from "./common/mobile";
import AuditorNav from "./auditornav";
import useWebSocket, { ReadyState } from 'react-use-websocket';

//import badwords from "./bad.json"

function Auditor(props) {
  const dispatch = useDispatch();
  // In functional React component
  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve('wss://148.251.44.2:9001/');
      }, 2000);
    });
  }, []);

  const { sendMessage, sendJsonMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    getSocketUrl,

    {
      onOpen: () => console.log('opened'),
      //Will attempt to reconnect on all close events, such as server shutting down
      shouldReconnect: (closeEvent) => true,
    }
  );
  const dreams = useSelector(getLoadCurrentMsgs);

  const [currentPage, setCurrentPage] = useState(1);

  const [isFilter, setIsFilter] = useState(false);
  const [mobileNO, setMobileNO] = useState("");
  const [listButtonsActive, setListButtonsActive] = useState({});

  const [filterUser, setFilterUser] = useState("");

  const [selectedSC, setSelectedSC] = useState("");
  const [search, SetSearch] = useState("");
  // const [currentDateVal, setCurrentDateVal] = useState();
  // const [msgLengthVal, setMsgLengthVal] = useState(0);
  const [msgStatus, setMsgStatus] = useState("");
  // const [msgLengthCond, setMsgLengthCond] = useState("");
  // const [dateCond, setDateCond] = useState("");

  const sendSocket = (dream) => {

    sendJsonMessage(dream, true);
  }


  const [listButtons, setListButtons] = useState([
    {
      title: "اليوم",
      active: false,
      indx: 0,
    },
    {
      title: "البارحة",
      active: false,
      indx: 1,
    },

    {
      title: "الشهر",
      active: false,
      indx: 2,
    },
    {
      title: "تدقيق",
      active: false,
      indx: 3,
    },
    {
      title: "محجوز",
      active: false,
      indx: 4,
    },
    {
      title: "محذوف",
      active: false,
      indx: 5,
    },
    {
      title: "مرسل",
      active: false,
      indx: 6,
    },
  ]);

  const [pageSize] = useState(20);

  const searchFunc = (m) => {
    if (m["mo_message"]) {
      var v = false;
      var boolarray = 0;
      const MOMsg = m["mo_message"] ? m["mo_message"] : "";
      const MTMsg = m["mt_reply_msg"] ? m["mt_reply_msg"] : "";
      const mobile = (m["mt_mobil"] + 971507354835).toString()

      var searcharr = search.split("-");
      if (searcharr.length > 0) {
        for (var i = 0; i < searcharr.length; i++) {
          if (MOMsg.includes(searcharr[i])) {
            boolarray++;
          }
        }
        if (boolarray && searcharr) {
          if (searcharr.length === boolarray) {
            return true;
          }
        }
      }


      v =
        MOMsg === search ||
        MTMsg === search ||
        mobile === search ||
        mobile.includes(search) ||
        //m["PersonStatus"].includes(searchQuery) ||
        MOMsg.includes(search) ||
        MTMsg.includes(search);


      return v;

    }
  }

  let dreamtotranslate = _.orderBy(
    dreams,
    ["mo_date", "mt_mobil"],
    ["asc", "asc"]);




  dreamtotranslate = dreamtotranslate.filter((a) =>
    mobileNO ? a.mt_mobil === mobileNO : a
  );



  dreamtotranslate = dreamtotranslate.filter((a) =>
    listButtonsActive ? listButtonsActive['title'] === "اليوم"
      ? new Date(a["mo_date"]).getDate() === new Date().getDate() &&
      new Date(a["mo_date"]).getFullYear() === new Date().getFullYear() &&
      new Date(a["mo_date"]).getMonth() === new Date().getMonth()
      : listButtonsActive['title'] === "البارحة"
        ? new Date(a["mo_date"]).getDate() === new Date(Date.now() - 86400000).getDate() &&
        new Date(a["mo_date"]).getFullYear() === new Date(Date.now() - 86400000).getFullYear() &&
        new Date(a["mo_date"]).getMonth() === new Date(Date.now() - 86400000).getMonth()
        : listButtonsActive['title'] === "الشهر"
          ?
          new Date(a["mo_date"]).getFullYear() === new Date().getFullYear() &&
          new Date(a["mo_date"]).getMonth() === new Date().getMonth()
          : listButtonsActive['title'] === "تدقيق"
            ? a["isreply"] === true &&
            a["isredytosent"] === false &&
            a["isdelete"] === false &&
            a["issent"] === false
            : listButtonsActive['title'] === "محجوز"
              ? a["isreply"] === false &&
              a["isredytosent"] === false &&
              a["isdelete"] === false &&
              a["issent"] === false &&
              a["islock"] === true
              : search !== "" ? searchFunc(a) : a

      : a
  );
  dreamtotranslate = dreamtotranslate.filter((a) =>
    msgStatus ? a.mt_arabic === msgStatus : a
  );
  dreamtotranslate = dreamtotranslate.filter((a) =>
    filterUser ? a.replyusername === filterUser || a.userlock === filterUser : a
  );
  dreamtotranslate = dreamtotranslate.filter((a) =>
    selectedSC ? a.moshortcode === parseInt(selectedSC) : a
  );

  /* const  checkbadwords=(msg) =>{
    const wordsArray = badwords.badwords;
    const yourString = msg;
    const result = wordsArray.every((w) => yourString.includes(w));
    return result;
  }
  const handleChangeInput =
    (dream) =>
    ({ currentTarget: input }) => {
      
      const newdream = { ...dream };
      newdream[input.name] = input.value;
      newdream["mt_date"] = new Date();
      dispatch(EditMsg(newdream));
    }; */

  const arrlengthtranslated = dreamtotranslate.filter((a) =>
    a.mt_reply_msg ? a.mt_reply_msg.length < 25 : true
  ).length;


  const handlelistButtons = (item) => {
    let newArr = [
      {
        title: "اليوم",
        active: false,
        indx: 0,
      },
      {
        title: "البارحة",
        active: false,
        indx: 1,
      },

      {
        title: "الشهر",
        active: false,
        indx: 2,
      },
      {
        title: "تدقيق",
        active: false,
        indx: 3,
      },
      {
        title: "محجوز",
        active: false,
        indx: 4,
      },
      {
        title: "محذوف",
        active: false,
        indx: 5,
      },
      {
        title: "مرسل",
        active: false,
        indx: 6,
      },
    ];
    if (listButtonsActive.indx === item.indx) {
      newArr[item.indx].active = false;
      setListButtons(newArr);
      setListButtonsActive({});
    } else {
      newArr[item.indx].active = true;
      setListButtons(newArr);
      setListButtonsActive(item);
      if (item.indx === 5) {
        dispatch(FilterListChange("محذوف"))
        window.open("/List", "_blank");
      } else if (item.indx === 6)
        dispatch(FilterListChange("مرسل"))
      window.open("/List", "_blank");

    }

  };
  const subminDreams = () => {
    dispatch(SendMsg(dreamtotranslate));
  };

  useEffect(() => {
    if (lastMessage) {
      const dream1 = JSON.parse(lastMessage.data)
      dispatch(EditMsg(dream1));


    }
  }, [lastMessage]);



  useEffect(() => {
    const timer = setTimeout(() => {
      if (navigator.onLine) {
        dispatch(LoadMsgsist());
        dispatch(loaduserlistfromDB());
        dispatch(LoadSentDeleteDreams());


      }
    }, 1000);
    return () => clearTimeout(timer);
  }, []);






  const handleFilter = (user) => {
    setFilterUser(user);
  };




  const handleMobileNO = (mob) => {
    setMobileNO(mob);
    if (mob === "") { setIsFilter(false); } else { setIsFilter(true); }


  }
  const CurrentPageChange = (page) => {
    setCurrentPage(page)
  }
  const itemsCount = dreamtotranslate ? dreamtotranslate.length : 0;
  const pagesCount = Math.ceil(itemsCount / pageSize);
  let currentPage_ = currentPage;
  if (pagesCount === 1) return null;
  const pages = _.range(1, pagesCount + 1);
  if (isFilter) currentPage_ = 0;
  return (
    <div className="mb-4">
      <AuditorNav
        isfinish={arrlengthtranslated}
        subminDreams={subminDreams}
        listButtons={listButtons}
        handlelistButtons={handlelistButtons}
        handleFilter={handleFilter}
        filterUser={filterUser}
        selectedSC={selectedSC}
        setSelectedSC={setSelectedSC}
        readyState={readyState}
        msgStatus={msgStatus}
        setMsgStatus={setMsgStatus}
      ></AuditorNav>



      <nav style={{ direction: "rtl" }}>
        <ul className="pagination  flex-wrap">
          {pages.map((page) => (
            <li
              key={page}
              className={
                page === currentPage_ ? "page-item active  " : "page-item "
              }
            >
              <a className="page-link" onClick={() => CurrentPageChange(page)}>
                {page}
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <input
        type="text"
        name="query"
        className="form-control my-3"
        placeholder="أكتب الكلمة ثم - ثم الكملة للبحث أو رقم الموبايل"
        value={search}
        onChange={e => SetSearch(e.currentTarget.value)}
      />
      <Mobile
        mobileno={mobileNO}
        setIsFilter={setIsFilter}
        removeMobile={handleMobileNO}
      ></Mobile>

      <AuditorDataTable
        dreams={dreamtotranslate}
        pageno={currentPage}
        pagesize={pageSize}
        setIsFilter={setIsFilter}
        isFilter={isFilter}
        ChangeMobileNO={handleMobileNO}
        sendSocket={sendSocket}

      />

      <br />
    </div>
  );
}

export default Auditor;
