 
import React, { useEffect, useState } from "react";
import logo from './logo.svg';
 import authService from "./services/authService";
import LoginForm from "./components/auth/loginForm";
import { ToastContainer } from "react-toastify";
  import { Route, Navigate, Routes } from "react-router-dom"; 
import {
  ProtectedRoute,
  PrivateRoute,
} from "./components/common/protectedRoute";
import Logout from "./components/auth/logout";

import NotFound from "./components/notFound";

 

 
import './App.css';
import Main from "./components/main";
import Book from "./components/book"; 
import History from "./components/history";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

 
import { LoadMsgsist } from "./store/messages/messages";
import { useDispatch } from "react-redux";
import Auditor from "./components/auditor";
 
import ListMsg from "./components/listMsg";
import History1 from "./components/history1";

function App() {
 
  const [user, setUser] = useState("");
  const [token, setToken] = useState({});
  


  useEffect(() => {

    window.addEventListener("offline", function (e) {
     
    });

    window.addEventListener("online", function (e) {
  
    });
    // fetch("http://localhost:3000/")
    //   .then((res) => res.json())
    //   .then((json) => {
    //     console.log(json);
    //   });
    const user = authService.getCurrentUser();
    // dispatch(LoadRepToday());
    // dispatch(LoadSCList());
    // dispatch(LoadRepMonth());
    // dispatch(LoadRepYear());
    // setUser(user);
  }, []);
 
   
 
    return (
      <React.Fragment>
        <ToastContainer />

        <Routes>
          <Route
            path="/login"
            exact
            element={<LoginForm setToken={setToken} />}
          />

          <Route path="/logout" exact element={<Logout />} />

          <Route exact path="/Main" element={<PrivateRoute />}>
            <Route exact path="/Main" element={<Main user={user} />} />
          </Route>

          <Route exact path="/Auditor" element={<PrivateRoute />}>
            <Route exact path="/Auditor" element={<Auditor user={user} />} />
          </Route>
          <Route exact path="/Book" element={<PrivateRoute />}>
            <Route exact path="/Book" element={<Book user={user} />} />
          </Route>
          <Route exact path="/List" element={<PrivateRoute />}>
            <Route exact path="/List" element={<ListMsg user={user} />} />
          </Route>
          <Route exact path="/History" element={<PrivateRoute />}>
            <Route exact path="/History" element={<History user={user} />} />
          </Route>
          <Route exact path="/History1" element={<PrivateRoute />}>
            <Route exact path="/History1" element={<History1 user={user} />} />
          </Route>
          {/* <Route exact path="/EditCustomer" element={<PrivateRoute />}>
            <Route
              exact
              path="/EditCustomer"
              element={<EditCustomer id="8" user={user} />}
            />
          </Route> */}

          {/* <Route exact path="/Messages" element={<PrivateRoute />}>
            <Route
              exact
              path="/Messages"
              element={<EditCustomer Messages id="8" user={user} />}
            />
          </Route> */}

          <Route path="/not-found" component={NotFound} />
          <Route path="/" exact element={<LoginForm setToken={setToken} />} />
        </Routes>
      </React.Fragment>
    );
  }
 
export default App;
