

import React from 'react';
import { DreamRelease } from '../../store/messages/messages';

function ListOfStatus({ dream, handleChangeStatus }) {
  const List = [
    "مكرر",
    "ناقص لا يفسر",
    "استشارة",
    "شكوى",
    "استخارة",
    "شكر",
    "تساؤل",
    "العاجل",
    "دعم فني",
  ];
  return (
    <div>
      <div className="m-2">
        <select
          onChange={handleChangeStatus(dream)}
          name={"ListStatus" + dream.replyid}
          id={"ListStatus" + dream.replyid}
          className="m-1 selectStatusList"
        >
          <option value="" key="0">
            الحالة
          </option>
          {List.map((list, index) => (
            <option key={"status" + index + dream.replyid} value={list}>
              {list}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default ListOfStatus;








