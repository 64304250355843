import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";

const Table = ({ columns, data }) => {
  return (
    <table className="table  table-bordered ">
      <TableHeader columns={columns} />
      <TableBody columns={columns} data={data} idkey="replyid" />
    </table>
  );
};

export default Table;
