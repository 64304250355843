import React from 'react';
import { useSelector } from 'react-redux';
import { getMsgHistory, getMsgToSend, getMsgToSent } from '../store/messages/messages';


function Nav  (props) {
  const dreamsToSend = useSelector(getMsgToSend);
  const MsgHistory = useSelector(getMsgHistory);

  const dreams = MsgHistory.filter((a) => 
    new Date(a["mt_date"]).getDate() === new Date().getDate() &&
    new Date(a["mt_date"]).getFullYear() === new Date().getFullYear() &&
    new Date(a["mt_date"]).getMonth() === new Date().getMonth()
  )
  const user = localStorage.getItem("user");
  const dreamcount=0;
  console.log(dreamsToSend);
  const dreamnotsent =1;
      return (
        <div>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="#">
              أهلا بك {user}
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div>{"   "}</div>
            <div className="p-2"> لقد قمت اليوم بارسال تفاسير  عدد {dreams && dreams.length}</div>
 
            <div className='position-absolute    start-0'> <button
                    type="button"
                    className="btn btn-primary  mr-4"
                    onClick={props.subminDreams}
                  >
                    أحفظ الأحلام وأرسلها
                  </button></div>
          </nav>
          {dreamnotsent && (
            <nav className="navbar-fixed-top navbar-expand-lg navbar-light bg-light p-3">
              <React.Fragment>
                <div className="col-9">أحلام مفسره لم ترسل بعد  {dreamsToSend&&dreamsToSend.length}</div>
              </React.Fragment>
              <div className="col-3">
                {props.isfinish === 0 && (
                  <button
                    type="button"
                    className="btn btn-success  "
                    onClick={props.subminDreams}
                  >
                    احفظ و أظهر المزيد من الأحلام
                  </button>
                )}
              </div>
            </nav>
          )}
        </div>
      );
};

export default Nav;