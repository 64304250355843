import React from 'react';
import PropTypes from 'prop-types';
import Table from './table/table';
import Moment from "react-moment";
import { EnvelopePaperFill, SendCheckFill, SendExclamationFill, Trash, Trash3Fill } from 'react-bootstrap-icons';
import { DeleteDream, EditMsg, SendUpdateMsgAPI } from '../store/messages/messages';
import { useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

 
function ListMsgDataTable({ dreams }) {
    const dispatch = useDispatch();
const stopSent=(dream)=>{
    const newdream = { ...dream };
    newdream["isredytosent"] = false;
  
    dispatch(SendUpdateMsgAPI(newdream))
}

    const checkSentResult=(result)=>{
        if( result && result.includes("MessageID=")){
          return true;
        }else {
          return false;
        }
    
      }
    const undelete=(dream)=>{


        confirmAlert({
          title: "اعادة الحلم",
          message: "هل ترغب بفك الحذف واعادة الحلم؟",
          buttons: [
            {
              label: "نعم",
              onClick: () => {
                let newdream = { ...dream };
                newdream["isdelete"] = false;
  
                dispatch(EditMsg(newdream));
                dispatch(DeleteDream());
              },
            },
            {
              label: "لا",
              onClick: () => {},
            },
          ],
        }); 
  
    
    }
    
  const deletemsg=(dream)=>{


    confirmAlert({
      title: "حذف الحلم",
      message: "هل ترغب بحذف الحلم الحلم؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            let newdream = { ...dream };
            newdream["isdelete"] =true;

            dispatch(EditMsg(newdream));
            dispatch(DeleteDream());
            //
          },
        },
        {
          label: "لا",
          onClick: () => {},
        },
      ],
    }); 


}
  const columns = [
  
    {
      path: "replyid",
      label: "التفاصيل",
      content: (dream) => (
 <div>{dream.replyusername}<br></br>
 {dream.moshortcode}<br></br>
 <p>  {  parseInt(dream.mt_mobil) + parseInt(971507354835) }</p>
 <p>  {   dream.personstatus }</p>
 <br></br>
 {dream.isdelete ? 
            <Trash3Fill
              size={30}
              className="m-1 cursor-pointer text-danger"
              onClick={() => undelete(dream)}
            >
              {" "}
            </Trash3Fill>
            : <Trash
            size={30}
            className="m-1 cursor-pointer "
            onClick={() => deletemsg(dream)}
          >
            {" "}
          </Trash>
          }

{dream.isredytosent && (
            <EnvelopePaperFill
              size={30}
              className="m-1 cursor-pointer text-warning"
            ></EnvelopePaperFill>
          )}
          {dream.issent && checkSentResult(dream.mt_resoult) ? (
            <SendCheckFill
              size={30}
              className="m-1 cursor-pointer text-success"
            ></SendCheckFill>
          ) : (
            <SendExclamationFill
              size={30}
              className="m-1 cursor-pointer text-danger"
            ></SendExclamationFill>
          )}
          {dream.isredytosent && !dream.issent  &&   <button
            onClick={() => stopSent(dream)}
            className="btn btn-outline-primary form-control float-end font-weight-bold"
          >
            ايقاف الارسال 
          </button>}

 </div>)},
    {
      key: 1,
      path: "mo_message",
      label: "الرسالة المرسلة",
      content: (dream) => (
 <div><p>  <Moment
 parse="YYYY-MM-DD HH:mm"
 format="YYYY-MM-DD hh:mm A"
 className="text-primary"
>
 {dream.mo_date}
</Moment></p>{dream.mo_message}</div>
      
      ),
    },
   
    {
      key: 2,
      path: "mt_reply_msg",
      label: "الرد للرسالة",
      content: (dream) => (
        <div><p> <Moment
        parse="YYYY-MM-DD HH:mm"
        format="YYYY-MM-DD hh:mm A"
        className="text-primary"
      >
        {dream.mt_date}
      </Moment></p>

        
        {dream.mt_reply_msg}
        </div>
       
      ),
    },
   

    
  ];
  return (
    <div>
      <Table columns={columns} data={dreams} idkey="replyid" />
    </div>
  );
}

export default ListMsgDataTable;