import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "ui_wp",
  initialState: {
    loading: false,
    lastFetch: null,
    isEdit: false,
    MsgType: "IMessageList",
    isNew: false,
    isLinked: false,
    isTableList: true,
    customerEdit:false,
    customerInfo:false,
    tabNo: 0,
    IMType: "list",
    IMTypeEdit: "list",
    header: "text",
    search_page_customer:0,
    search_page_general:0,
    
    TMessage: {
      IM: {
        HierarchyTree: {
          IsHierarchyTree: false,
          IMID: 0,
          data: [],
        },
        NewIMType: "",
      },
      typeOfIM: ["list", "button", "product", "product_list"],
    },
  },

  //search-minus
  reducers: {
    TMessageReqused: (ui_wp, action) => {
      //   category.list = action.payload;
      //   console.log(action);
    },
  UI_MsgUpdateCustomerReqused: (ui_wp, action) => {
      ui_wp.isEdit = action.payload.data.isEdit;
      ui_wp.customerEdit = action.payload.data.customerEdit;

  },

    UI_MsgUpdateReqused: (ui_wp, action) => {
      ui_wp.isEdit = action.payload.data.isEdit;
      ui_wp.MsgType = action.payload.data.MsgType;
      ui_wp.isNew = action.payload.data.isNew;
      ui_wp.isLinked = action.payload.data.isLinked;
      ui_wp.isTableList = action.payload.data.isTableList;
      ui_wp.IMType = action.payload.data.IMType;
    ui_wp.IMTypeEdit=action.payload.data.IMTypeEdit;
      if (ui_wp.MsgType === "IMessageList") {
        ui_wp.tabNo = 0;
      } else if (ui_wp.MsgType === "AudioList") {
        ui_wp.tabNo = 4;
      } else if (ui_wp.MsgType === "VideoList") {
        ui_wp.tabNo = 3;
      } else if (ui_wp.MsgType === "DocumentList") {
        ui_wp.tabNo = 5;
      } else if (ui_wp.MsgType === "ImageList") {
        ui_wp.tabNo = 2;
      } else if (ui_wp.MsgType === "MsgTextList") {
        ui_wp.tabNo = 1;
      } else if (ui_wp.MsgType === "LocationList") {
        ui_wp.tabNo = 6;
      } else if (ui_wp.MsgType === "ContactsList") {
        ui_wp.tabNo = 7;
      }

      //ui_wp.tabNo = action.payload.data;
    },
    UI_TabUpdateReqused: (ui_wp, action) => {
      if (ui_wp.MsgType === "IMessageList") {
        ui_wp.tabNo = 0;
      } else if (ui_wp.MsgType === "AudioList") {
        ui_wp.tabNo = 4;
      } else if (ui_wp.MsgType === "VideoList") {
        ui_wp.tabNo = 3;
      } else if (ui_wp.MsgType === "DocumnetList") {
        ui_wp.tabNo = 5;
      } else if (ui_wp.MsgType === "ImageList") {
        ui_wp.tabNo = 2;
      } else if (ui_wp.MsgType === "MsgTextList") {
        ui_wp.tabNo = 1;
      } else if (ui_wp.MsgType === "LocationList") {
        ui_wp.tabNo = 6;
      } else if (ui_wp.MsgType === "ContactsList") {
        ui_wp.tabNo = 7;
      } else if (ui_wp.MsgType === "StickerList") {
        ui_wp.tabNo = 0;
      }

      //ui_wp.tabNo = action.payload.data;
    },

    UI_IMsgTypeUpdateReqused: (ui_wp, action) => {
      ui_wp.IMType = action.payload.data;
      ui_wp.IMTypeEdit = action.payload.data;
      //ui_wp.tabNo = action.payload.data;
    },
    UI_IMsgTypeEditUpdateReqused: (ui_wp, action) => {
      ui_wp.IMTypeEdit = action.payload.data;
      ui_wp.IMType = action.payload.data;

      //ui_wp.tabNo = action.payload.data;
    },

    UI_IM_HeaderUpdateReqused: (ui_wp, action) => {
      ui_wp.header = action.payload.data;

      //ui_wp.tabNo = action.payload.data;
    },
    TMessageAddNewReqused: (ui_wp, action) => {
      ui_wp.TMessage = action.payload;
      console.log(action);
    },

    CreateCategoryReqused: (ui_wp, action) => {
      // category.list.results.push(action.payload);
    },
    ShowHierarchyTreeIMUI: (ui_wp, action) => {
      ui_wp.TMessage.IM.HierarchyTree.IMID = action.payload.data["id"];
      ui_wp.TMessage.IM.HierarchyTree.IsHierarchyTree = true;
      ui_wp.TMessage.IM.HierarchyTree.data = action.payload.data;
    },
    HideHierarchyTreeIMUI: (ui_wp, action) => {
      ui_wp.TMessage.IM.HierarchyTree.IMID = 0;
      ui_wp.TMessage.IM.HierarchyTree.IsHierarchyTree = false;
      ui_wp.TMessage.IM.HierarchyTree.data = [];
    },
    NewIM_Type_UI: (ui_wp, action) => {
      ui_wp.TMessage.IM.NewIMType = action.payload.data;
    },
    ChangeTabNoUI: (ui_wp, action) => {
      ui_wp.tabNo = action.payload.data;
    },
  },
});

const {
  ShowHierarchyTreeIMUI,
  HideHierarchyTreeIMUI,
  NewIM_Type_UI,
  ChangeTabNoUI,
  UI_MsgUpdateReqused,
  UI_IMsgTypeUpdateReqused,
  UI_TabUpdateReqused,
  UI_IM_HeaderUpdateReqused,
  UI_IMsgTypeEditUpdateReqused,
} = slice.actions;
export default slice.reducer;

export const ChangeTabNo_UI = (data) => (dispatch, getState) => {
  return dispatch(
    ChangeTabNoUI({
      data,
    })
  );
};

export const UI_IM_HeaderUpdate = (data) => (dispatch, getState) => {
  return dispatch(
    UI_IM_HeaderUpdateReqused({
      data,
    })
  );
};

export const UI_TabUpdate = (data) => (dispatch, getState) => {
  return dispatch(
    UI_TabUpdateReqused({
      data,
    })
  );
};

export const Change_IM_Type_UI = (data) => (dispatch, getState) => {
  return dispatch(
    UI_IMsgTypeUpdateReqused({
      data,
    })
  );
};
export const Change_IM_Type_Edit = (data) => (dispatch, getState) => {
  return dispatch(
    UI_IMsgTypeEditUpdateReqused({
      data,
    })
  );
};

export const UI_MsgDataUpdate = (data) => (dispatch) => {
  return dispatch(
    UI_MsgUpdateReqused({
      data,
    })
  );
};

export const NewIM_type_UI = (data) => (dispatch) => {
  return dispatch(
    NewIM_Type_UI({
      data,
    })
  );
};
export const ShowHierarchyTree_IM_UI = (data) => (dispatch, getState) => {
  return dispatch(
    ShowHierarchyTreeIMUI({
      data,
    })
  );
};
export const change_IM_UI = () => {
  HideHierarchyTreeIMUI({
    data: [],
  });
};

//Sekectors
export const getTMessageUI = createSelector(
  (state) => state.entities.ui_wp.TMessage,

  (TMessage) => (TMessage ? TMessage : [])
);

export const getUI = createSelector(
  (state) => state.entities.ui_wp,

  (ui_wp) => ui_wp
);

export const getMsgTypeUI = createSelector(
  (state) => state.entities.ui_wp.MsgType,

  (MsgType) => MsgType
);
