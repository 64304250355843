import React from 'react';
import PropTypes from 'prop-types';
import Table from './table/table';
import Moment from "react-moment";

 
function HistoryDataTable({ dreams }) {
  
  const columns = [
  
    {
      path: "replyid",
      label: "التفاصيل",
      content: (dream) => (
 <div>{dream.replyusername}<br></br>
 {dream.moshortcode}<br></br>
 {dream.isdelete ? (
          <i className={"fa fa-trash  fa-lg text-danger"}></i>
        ) : (
          ""
        )}
 </div>)},
    {
      key: 1,
      path: "mo_message",
      label: "الرسالة المرسلة",
      content: (dream) => (
 <div><p>  <Moment
 parse="YYYY-MM-DD HH:mm"
 format="YYYY-MM-DD hh:mm A"
 className="text-primary"
>
 {dream.mo_date}
</Moment></p>{dream.mo_message}</div>
      
      ),
    },
   
    {
      key: 2,
      path: "mt_reply_msg",
      label: "الرد للرسالة",
      content: (dream) => (
        <div><p> <Moment
        parse="YYYY-MM-DD HH:mm"
        format="YYYY-MM-DD hh:mm A"
        className="text-primary"
      >
        {dream.mt_date}
      </Moment></p>

        
        {dream.mt_reply_msg}
        </div>
       
      ),
    },
   

    
  ];
  return (
    <div>
      <Table columns={columns} data={dreams} idkey="replyid" />
    </div>
  );
}

export default HistoryDataTable;