import http from "./httpService";
import apiUrl from "../config.json";
import { toast } from "react-toastify";
import { userAuth } from "../store/user";
import { Buffer } from "buffer";

const apiEndpoint = apiUrl.apiUrl + "token/t/";
const tokenKey = "token";

export async function Login(username, password) {
  console.log("0");
  // var data =
  //   "password=" + password + "&username=" + username + "&grant_type=password";
  var bodyFormData = new FormData();
  bodyFormData.set("username", username);
  bodyFormData.set("password", password);
  console.log(apiEndpoint);

  const token = await http.post(apiEndpoint, bodyFormData);
   
  

  if (token.data.token) {
    const encodedString = Buffer.from(
      username + ":" + password,
      "utf8"
    ).toString("base64");
    console.log(encodedString);
   
    
 
    localStorage.setItem("token", encodedString);

    localStorage.setItem("user", username);
  
  
    console.log(localStorage.getItem("token"));
    return {
      token: token.data.token,
    
      user: username,
  
    };
  }

  return null;
}

export function logout() {
 // localStorage.removeItem("persist:root");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("wa");
  localStorage.removeItem("id");
}

export function getCurrentUser() {
  try {
    const user = localStorage.getItem("user");

    return user;
  } catch (ex) {
    return null;
  }
}

export function getUserPermission() {
  try {
    const userPermission = localStorage.getItem("userGroup");

    return userPermission;
  } catch (ex) {
    return null;
  }
}

export function getToken() {
  return localStorage.getItem("token");
}


export default {
  Login,
  getUserPermission,
  logout,
  getCurrentUser,
  getToken,
};
