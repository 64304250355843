import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  EditMsg,
  SendMsg,
  getLoadCurrentMsgs,
  LoadMsgsist,
  LoadMsgsistLocked,
  LoadMsgsToLocked,
  getMsgToSend,
  SendMsgs,
  getListMsgs_current,
  MODreamsUpdateDB,
  getloading,
  BulkDataSent,
} from "../store/messages/messages";
import { userAuth } from "../store/user";
import Pagination from "../utils/pagination";
import _ from "lodash";
import MainDataTable from "./mainDataTable";
import Nav from "./nav";
import {
  DoorOpenFill
} from "react-bootstrap-icons";
import authService, { logout } from "../services/authService";
//import badwords from "./bad.json"
 
function Main(props) {
  const dispatch = useDispatch();
  const dreams = useSelector(getListMsgs_current);
  const dreamtosend = useSelector(getMsgToSend);
  const load = useSelector(getloading);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFinish, setIsFinish] = useState(false);
  const user = localStorage.getItem("user");
  const [pageSize] = useState(25);
  const sendMessages = () => {
    if (navigator.onLine) { 
      const dreamtosend = dreams.filter((a) => a.isreply === true);
     
      dispatch(MODreamsUpdateDB(dreamtosend));
  
      dispatch(BulkDataSent(dreamtosend));

    }
}
  console.log(dreams);
  const logOut = () => {
  logout();

    window.location = "/";
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (navigator.onLine) {
        dispatch(LoadMsgsistLocked());
      }

    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  const CurrentPageChange = (page) => {
    setCurrentPage(page)
  }
  const itemsCount = dreams ? dreams.length : 0;
  const pagesCount = Math.ceil(itemsCount / pageSize);
  let currentPage_ = currentPage;
  //if (pagesCount === 1) return null;
  const pages = _.range(1, pagesCount + 1);
 
  return (

    <div className="mb-4 m-2">
      <nav className="navbar  start-0">
        
        <DoorOpenFill size={30}
          onClick={() => logOut()}
          className="m-1 cursor-pointer text-danger">
          
          </DoorOpenFill>
<br></br>
        <div >  لقد قمت اليوم بارسال تفاسير  عدد {dreams && dreams.filter((a) => a.isreply === true).length} </div>
        <div className="p-2"><button
          type="button"
          className="btn btn-success  "
          onClick={() => sendMessages()}
        >
          ارسال كافة الأحلام المفسرة
        </button></div>

        <div className='position-absolute    start-0'>      {load.loading === true && "جاري الحفظ أو الارسال"}</div>
      </nav>
  
      
    
      <nav style={{ direction: "rtl" }}>
        <ul className="pagination  flex-wrap">
          {pages.map((page) => (
            <li
              key={page}
              className={
                page === currentPage_ ? "page-item active  " : "page-item "
              }
            >
              <a role="button"  className="page-link" onClick={() => CurrentPageChange(page)}>
                {page}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      <MainDataTable
        dreams={dreams}
        pageno={currentPage}
        pagesize={pageSize}
      />

      <br />
    </div>
  );
}

export default Main;
