import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch ,useSelector } from 'react-redux';
import { GetClientMsgList, getMessageHistory } from '../store/messages/messages';
import SearchBox from './common/searchBox';
import HistoryDataTable1 from './historyDataTable1';
import _ from "lodash";
import Pagination from '../utils/pagination';
import { paginate } from '../utils/paginate';
import authService from '../services/authService';
export default function History1() {
      const dreams =  useSelector(getMessageHistory);
      const [search, setSearch] = useState("");
      const [currentPage, setCurrentPage] = useState(0);
      const ChangeCurrentpage=(cpage)=>{
            setCurrentPage(cpage);
      }
      let dreamtotranslate = _.orderBy(
            dreams,
            ["mo_date"],
            ["desc"]
          );
          dreamtotranslate = dreamtotranslate.filter((m) =>
          search ?m["mo_message"] && m["mt_reply_msg"]?m["mo_message"].includes(search)||
          m["mt_reply_msg"].includes(search):m["mo_message"] &&m["mo_message"].includes(search)
           : m
          );
         let dreampaination   = paginate(dreamtotranslate, currentPage, 20);
  const dispatch = useDispatch();
        useEffect(() => {
    const timer = setTimeout(() => {
      if(navigator.onLine){
            dispatch(GetClientMsgList());
     
      }


    }, 1000);
     return () => clearTimeout(timer);
        }, []);
      const user = authService.getCurrentUser();
      return (user === "adib" &&
            <div> <div> <p>  {dreams && dreams[0]&&  parseInt(dreams[0].mt_mobil) + parseInt(971507354835) }</p><p>عدد الأحلام المستلمة من العميل {dreams.length} </p><p>
                  {dreams && dreams[0] && dreams[0].personstatus}
                  </p></div>
            <SearchBox value={search} onChange={setSearch} />
                   <Pagination
        itemsCount={dreamtotranslate ? dreamtotranslate.length : 0}
        pageSize={20}
        currentPage={currentPage}
        onPageChange={ChangeCurrentpage}
    
      ></Pagination>
        <HistoryDataTable1 dreams={dreampaination}></HistoryDataTable1>
            </div>
      );
}


 