import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import axios from "axios";
import { apiCallBegan } from "../api";
import moment from "moment";
import { UI_MsgDataUpdate } from "../UI/ui_wp";
import { Immer } from "immer";
import _ from "lodash";
import badwords from "../../components/bad.json"
function getDifference(array1, array2) {
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1.replyid === object2.replyid;
    });
  });
}
function checkbadwords(msg) {
  const wordsArray = badwords.badwords;
  const yourString = msg;
  const result = wordsArray.every((w) => yourString.includes(w));
  return result;
}
const slice = createSlice({
  name: "messages",
  initialState: {
    CurrentMsgList: [],
    MsgList: [],
    MsgToSend: [],
    MsgToSent: [],
    CurrentMsg: [],
    msgHistory: [],
    dreams_Sent_Delete: [],
    lastDreamTranslate: [],
    loading: false,
    lastFetch: null,
    isCTextBadWord: false,
    currentHistpryMobile: "",
    ClientMsgListHistory: [],
    filter: {
      date: new Date(),
      datecond: "",
      msgLengthVal: 0,
      msgLengthCond: "",
      list: ""
    },
  },
  reducers: {
    RequestStart: (messages, action) => {
      messages.loading = true;
    },
    RequestList: (messages, action) => {
      messages.loading = true;
    },
    MsgListLockedRequsted: (messages, action) => {
      messages.MsgList = action.payload;
      const cmsgs = current(messages.CurrentMsgList);
      const newdreams = _.differenceBy(action.payload, cmsgs, "replyid");

      if (newdreams.length > 0) {
        if (cmsgs.length === 0) {
          messages.CurrentMsgList = action.payload;
        } else {
          messages.CurrentMsgList = [...cmsgs, ...newdreams];
        }
      }
    },

    MsgListLockedAdibRequsted: (messages, action) => {
      messages.MsgList = action.payload;
      const cmsgs = current(messages.CurrentMsgList);
      const newdreams = _.differenceBy(action.payload, cmsgs, "replyid");

      if (newdreams.length > 0) {
        if (cmsgs.length === 0) {
          messages.CurrentMsgList = action.payload;
        } else {
          messages.CurrentMsgList = [...cmsgs, ...newdreams];
        }
      }
      
    },
    MsgListRequsted: (messages, action) => {
      messages.MsgList = action.payload;
      const cmsgs = current(messages.CurrentMsgList);
      const newdreams = _.differenceBy(action.payload, cmsgs, "replyid");

      if (newdreams.length > 0) {
        if (cmsgs.length === 0) {
          messages.CurrentMsgList = action.payload;
        } else {
          messages.CurrentMsgList = [...cmsgs, ...newdreams];
        }
      }
    },


    ChangeSentData:(messages, action) => {
      let cmsgs = current(messages.CurrentMsgList);
      const cmsgshistory = current(messages.msgHistory);
      const sentmessage = action.payload.data;
   
      for (let index in sentmessage) {
     
        cmsgs = cmsgs.filter((a) => a.replyid !== sentmessage[index].replyid);

      }
  
      if (cmsgshistory) {
        if (cmsgshistory.length > 200) {

          cmsgshistory = cmsgshistory.splice(0, sentmessage.length);
          messages.msgHistory = [...cmsgshistory, ...sentmessage];
        } else {
          messages.msgHistory = [...cmsgshistory, ...sentmessage];
        }

      } else {
        messages.msgHistory = [...sentmessage];
      }
      messages.CurrentMsgList = cmsgs;

},
    RequestFinish: (messages, action) => {
      messages.loading = false;
    },
    RequestFinish: (messages, action) => {
      messages.loading = false;
    },
    RequestFailed: (messages, action) => {
      messages.loading = false;
    },
    MsgSent: (messages, action) => {

      messages.loading = false;
    },
    MsgSent_Delete: (messages, action) => {

      messages.dreams_Sent_Delete = action.payload;
    },

    RequestUpdatefromDB: (messages, action) => {
      const cmsgList = current(messages.CurrentMsgList);
      const cmsgs = current(messages.CurrentMsg);
      // const CmsgToSnet = current(messages.dreamsSent);
      //const cmsgshistory = current(messages.msgHistory);
      const cmsgtosned = current(messages.MsgToSend);
      const cmsgtosnet = current(messages.MsgToSent);
      const indexlist = cmsgList.findIndex(
        (a) => a.replyid === action.payload[0].replyid
      );
      const sendlist = cmsgList.filter(
        (a) => a.replyid === action.payload[0].replyid
      );

      messages.MsgToSend = sendlist;
      messages.CurrentMsgList[indexlist] = action.payload[0];
      messages.CurrentMsg = action.payload[0];
      if (cmsgtosnet.length > 250) cmsgtosnet.shift();
      messages.MsgToSent = cmsgtosnet.length === 0 ? action.payload[0] : [...cmsgtosnet, action.payload[0]];
      //messages.msgHistory =cmsgshistory.length ===0?action.payload[0]: [...cmsgshistory, action.payload[0]];



    },
    PutHistoryMobile: (messages, action) => {
      messages.currentHistpryMobile = action.payload.data;
    },
    RequestClientMsgHistory: (messages, action) => {
      messages.ClientMsgListHistory = action.payload;
    },
    change_MO_TO_HTML: (messages, action) => {

    },
    MsgsSent: (messages, action) => { 
      const cmsgtosned = current(messages.MsgToSend);
      const arr2 = action.payload.status;
      const getsent = cmsgtosned.filter((x) => !arr2.includes(x.replyid));
      if (getsent.length> 0){
        messages.MsgToSend = getsent;
      } else {
        messages.MsgToSend = [];
      }
      

    },
    MsgSend: (messages, action) => {
      const cmsgs = current(messages.CurrentMsgList);
      const cmsgList = current(messages.MsgList);
      const cmsgshistory = current(messages.msgHistory);
      const cmsgtosned = current(messages.MsgToSend);
      const msgtosend = action.payload.data;
      const newcmsgs = _.differenceBy(cmsgs, msgtosend, "replyid");
      const newcmsgList = _.differenceBy(
        cmsgList,
        msgtosend,

        "replyid"
      );
      messages.CurrentMsgList = newcmsgList;
      messages.MsgList = newcmsgs;
      if (cmsgtosned) { messages.MsgToSend = [...cmsgtosned, ...msgtosend]; }
      else { messages.MsgToSend = [...msgtosend]; }
      if (cmsgshistory) {
        if (cmsgshistory.length > 200) {
          cmsgshistory = cmsgshistory.splice(0, 1);
          messages.msgHistory = [...cmsgshistory, ...msgtosend];
        } else {
          messages.msgHistory = [...cmsgshistory, ...msgtosend];
        }

      }
      else {
        messages.msgHistory = [...msgtosend];
      }

    },
    RequestLockedUnlockedDream: (messages, action) => {

      const cmsgList = current(messages.CurrentMsgList);
      const cmsgs = current(messages.CurrentMsg);

      const indexlist = cmsgList.findIndex(
        (a) => a.replyid === action.payload[0].replyid
      );


      messages.CurrentMsgList[indexlist] = action.payload[0];
      messages.CurrentMsg = action.payload[0];
    },
    msgReplyEditing: (messages, action) => {
      const cmsgList = current(messages.CurrentMsgList);
      const cmsgs = current(messages.CurrentMsg);

      const indexlist = cmsgList.findIndex(
        (a) => a.replyid === action.payload.data.replyid
      );

    
      messages.CurrentMsgList[indexlist] = action.payload.data;
      messages.CurrentMsg = action.payload.data;
    },
    FilterDate: (messages, action) => {
      messages.filter.date = action.payload.data;
    },
    FilterDateCond: (messages, action) => {
      messages.filter.datecond = action.payload.data;
    },
    FilterMsgLeng: (messages, action) => {
      messages.filter.msgLengthVal = action.payload.data;
    },
    FilterMsgLengCond: (messages, action) => {
      messages.filter.msgLengthCond = action.payload.data;
    },
    FilterList: (messages, action) => {
      messages.filter.list = action.payload.data;
    },
  },
});

export const {
  FilterDate,
  FilterDateCond,
  FilterMsgLeng,
  FilterMsgLengCond,
  RequestStart,
  RequestFinish,
  RequestFailed,
  RequestList,
  MsgListRequsted,
  MsgSent,
  MsgSend,
  MsgSent_Delete,
  msgReplyEditing,
  change_MO_TO_HTML,
  MsgListLockedRequsted,
  MsgListLockedAdibRequsted,
  RequestUpdatefromDB,
  PutHistoryMobile,
  RequestClientMsgHistory,
  RequestLockedUnlockedDream,
  FilterList,
  MsgsSent,
  ChangeSentData,

} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "/messages";

const urlAPI = "api2/";


export const BulkDataSent = (dreams) => {
  return (dispatch, getState) => {
    dispatch(
      ChangeSentData({
        data: dreams,
      })
    );
  };
};


export const ChangeHistpryMobile = (mob) => {
  return (dispatch, getState) => {
    dispatch(
      PutHistoryMobile({
        data: mob,
      })
    );
  };
};
export const FilterListChange = (val) => {
  return (dispatch, getState) => {

    dispatch(
      FilterList({
        data: val,
      })
    );
  }
}
export const FilterChange = (Kof, val) => {
  return (dispatch, getState) => {
    if (Kof === "date") {
      dispatch(
        FilterDate({
          data: val,
        })
      );
    } else if (Kof === "datecond") {
      dispatch(
        FilterDateCond({
          data: val,

        })
      );
    } if (Kof === "msgleng") {
      dispatch(
        FilterMsgLeng({
          data: val,
        })
      );
    } if (Kof === "msglengCond") {
      dispatch(
        FilterMsgLengCond({
          data: val,
        })
      );
    }

  };
};
export const EditMsg = (dream) => {
  return (dispatch, getState) => {
    dispatch(
      msgReplyEditing({
        data: dream,
      })
    );
  };
};
export const DreamRelease = () => (dispatch, getState) => {
  const Data = getState().entities.messages.CurrentMsg;
  return dispatch(
    apiCallBegan({
      url: urlAPI + "/",
      method: "post",
      data: Data,
      onStart: RequestStart.type,
      onSuccess: RequestUpdatefromDB.type,
      onError: RequestFailed.type,
    })
  );
};

export const DreamRemoveUserReply = () => (dispatch, getState) => {
  const Data = getState().entities.messages.CurrentMsg;
  return dispatch(
    apiCallBegan({
      url: urlAPI + "/",
      method: "post",
      data: Data,
      onStart: RequestStart.type,
      onSuccess: RequestUpdatefromDB.type,
      onError: RequestFailed.type,
    })
  );
};

export const MODreamUpdateDB = (dream) => (dispatch, getState) => {

  return dispatch(
    apiCallBegan({
      url: "msg/u/",
      method: "post",
      data: dream,
      onStart: RequestStart.type,
      onSuccess: RequestUpdatefromDB.type,
      onError: RequestFailed.type,
    })
  );
};


export const MODreamsUpdateDB = (dreams) => (dispatch, getState) => {

  return dispatch(
    apiCallBegan({
      url: "msg/u_u/",
      method: "post",
      data: dreams,
      onStart: RequestStart.type,
      onSuccess: RequestFinish.type,
      onError: RequestFailed.type,
    })
  );
};


export const GetClientMsgList = () => (dispatch, getState) => {
  const mob = getState().entities.messages.currentHistpryMobile;
  console.log(mob);
  return dispatch(
    apiCallBegan({
      url: "msglist/m/" + String(mob),
      onStart: RequestStart.type,
      onSuccess: RequestClientMsgHistory.type,
      onError: RequestFailed.type,
    })
  );
};




export const ChangeStatus = (dream) => (dispatch, getState) => {

  return dispatch(
    apiCallBegan({
      url: urlAPI + "/",
      method: "post",
      data: dream,
      onStart: RequestStart.type,
      onSuccess: MsgSent.type,
      onError: RequestFailed.type,
    })
  );
};

export const DeleteDream = () => (dispatch, getState) => {
  const Data = getState().entities.messages.CurrentMsg;
  return dispatch(
    apiCallBegan({
      url: "dream/d/",
      method: "post",
      data: Data,
      onStart: RequestStart.type,
      onSuccess: RequestLockedUnlockedDream.type,
      onError: RequestFailed.type,
    })
  );
};

export const LockedDream = () => (dispatch, getState) => {
  const Data = getState().entities.messages.CurrentMsg;
  return dispatch(
    apiCallBegan({
      url: "dream/lul/",
      method: "post",
      data: Data,
      onStart: RequestStart.type,
      onSuccess: RequestLockedUnlockedDream.type,
      onError: RequestFailed.type,
    })
  );
};

export const LoadDreamHistpry = () => (dispatch, getState) => {
  const mobile = getState().entities.messages.currentHistpryMobile;
  return dispatch(
    apiCallBegan({
      url: urlAPI + "/",
      method: "post",
      data: mobile,
      onStart: RequestStart.type,
      onSuccess: MsgSent.type,
      onError: RequestFailed.type,
    })
  );
};




export const LoadSentDeleteDreams = () => (dispatch, getState) => {

  return dispatch(
    apiCallBegan({
      url: "msg/sd/",
      onStart: RequestStart.type,
      onSuccess: MsgSent_Delete.type,
      onError: RequestFailed.type,
    })
  );
};

// export const UnLockedDream = () => (dispatch, getState) => {
//   const Data = getState().entities.messages.MsgToSend;
//   return dispatch(
//     apiCallBegan({
//       url: urlAPI + "/",
//       method: "post",
//       data: Data,
//       onStart: RequestStart.type,
//       onSuccess: MsgSent.type,
//       onError: RequestFailed.type,
//     })
//   );
// };

export const SendMsg = (dreams) => {
  return (dispatch, getState) => {
    dispatch(
      MsgSend({
        data: dreams,
      })
    );
  };
};

export const LoadMsgsistLocked = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "getallmsgbyuser/mu/",
      onStart: RequestStart.type,
      onSuccess: MsgListLockedAdibRequsted.type,
      onError: RequestFailed.type,
    })
  );
};
export const LoadMsgsToLocked = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "getallmsgbyuser/mul/",
      onStart: RequestStart.type,
      onSuccess: MsgListLockedRequsted.type,
      onError: RequestFailed.type,
    })
  );
};




export const LoadMsgsist = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "msg/m/",
      onStart: RequestStart.type,
      onSuccess: MsgListRequsted.type,
      onError: RequestFailed.type,
    })
  );
};



export const SendUpdateMsgAPI = (msg) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "msg/u/",
      method: "post",
      data: msg,
      onStart: RequestStart.type,
      onSuccess: MsgSent.type,
      onError: RequestFailed.type,
    })
  );
};

export const SendMsgs = () => (dispatch, getState) => {
  const Data = getState().entities.messages.MsgToSend;
  if (Data.length > 0) {
    return dispatch(
      apiCallBegan({
        url: urlAPI + "msg/u_u/",
        method: "post",
        data: Data,
        onStart: RequestStart.type,
        onSuccess: MsgsSent.type,
        onError: RequestFailed.type,
      })
    );
  }
  
};

// export const MsgShare = (audio,IMType) =>  {
//     if (IMType==="Row") {
//   return (dispatch, getState) => {
//     const linkedRow = getState().entities.imessages.linkedToRow;

//       console.log(linkedRow);
//       console.log(audio);
//       dispatch(
//         apiCallBegan({
//           url: urlAPI + "AudioCompanyList/" + audio.audio_id + "/",
//           method: "put",
//           data: { Interactive_Rows: linkedRow.Interactive_Rows_id },
//           onStart: RequestStart.type,
//           onSuccess: AudioUpdateLinkedRequsted.type,
//           onError: RequestFailed.type,
//         })
//       );
//       dispatch(
//         UI_MsgDataUpdate({
//           isEdit: true,
//           MsgType: "IMessageList",
//           isNew: false,
//           isLinked: false,
//           isTableList: false,
//           IMType: "list",
//           IMTypeEdit: "list",
//         })
//       );

//       setTimeout(() => {
//         dispatch(LinkToRowConnect(null));
//         dispatch(loadIMessageList());
//       }, 200);
//     }
//   }else if (IMType==="Button") {
//        return (dispatch, getState) => {

//     const linkedButton = getState().entities.imessages.linkedToButton;
//       console.log(linkedButton);
//       console.log(audio);
//       dispatch(
//         apiCallBegan({
//           url: urlAPI + "AudioCompanyList/" + audio.audio_id + "/",
//           method: "put",
//           data: { Interactive_Buttons: linkedButton.Interactive_Buttons_id },
//           onStart: RequestStart.type,
//           onSuccess: AudioUpdateLinkedRequsted.type,
//           onError: RequestFailed.type,
//         })
//       );
//       dispatch(
//         UI_MsgDataUpdate({
//           isEdit: true,
//           MsgType: "IMessageList",
//           isNew: false,
//           isLinked: false,
//           isTableList: false,
//           IMType: "button",
//           IMTypeEdit: "button",
//         })
//       );

//       setTimeout(() => {
//         dispatch(LinkToButtonConnect(null));
//         dispatch(loadIMessageButtons());
//       }, 200);
//     }
//   };

// };

// export const MsgToEdit = (msg)  => {
//   return (dispatch) => {
//     dispatch(ToEdit_Requsted(msg));
//     dispatch(
//       UI_MsgDataUpdate({
//         isEdit: true,
//         MsgType: "AudioList",
//         isNew: false,
//         isLinked: false,
//         isTableList: false,
//       })
//     );
//   };
// };

// export const MsgDelete = (msg) => (dispatch) => {
//   return dispatch(
//     apiCallBegan({
//       url: urlAPI + "AudioCompanyList/" + msg.audio_id + "/",
//       method: "put",
//       data: { is_delete: true },
//       onStart: RequestStart.type,
//       onSuccess: MsgDeleteRequsted.type,
//       onError: RequestFailed.type,
//     })
//   );
// };

// export const MsgUpdate = (msg) => (dispatch, getState) => {
//   const audiodData = getState().entities.audios.Audio;
//   return dispatch(
//     apiCallBegan({
//       url: urlAPI + "AudioCompanyList/" + audiodData.audio_id + "/",
//       method: "put",
//       data: msg,
//       onStart: RequestStart.type,
//       onSuccess: MsgUpdateRequsted.type,
//       onError: RequestFailed.type,
//     })
//   );
// };

// export const MsgEdit = (msg) => (dispatch) => {
//   return dispatch(MsgEdit_Requsted({ type: msg.type, value: msg.value }));
// };
// export const MsgNew = () => {
//   return (dispatch) => {
//     dispatch(MsgNew_Requsted());
//     dispatch(
//       UI_MsgDataUpdate({
//         isEdit: false,
//         MsgType: "AudioList",
//         isNew: true,
//         isLinked: false,
//         isTableList: false,
//       })
//     );
//   };
// };

// export const CreateMsg = (msg) => (dispatch) => {
//   return dispatch(
//     apiCallBegan({
//       url: urlAPI + "AudioCompanyList/",
//       method: "post",
//       data: msg,
//       onStart: RequestStart.type,
//       onSuccess: AddMsgRequested.type,
//       onError: RequestFailed.type,
//     })
//   );
// };

// export const MsgLinked = (audio) => (dispatch, getState) => {
//   const linkedRow = getState().entities.audios.linkedToRow;
//   return dispatch(
//     apiCallBegan({
//       url: urlAPI + "AudioCompanyList/" + audio.audio_id + "/",
//       method: "put",
//       data: { Interactive_Rows: linkedRow.Interactive_Rows_id },
//       onStart: RequestStart.type,
//       onSuccess: AudioUpdateLinkedRequsted.type,
//       onError: RequestFailed.type,
//     })
//   );
// };

// export const MsgUnLinked_Audio = (msg,IMType) => {

//   return (dispatch) => {
//     if (IMType==="Row"){
//  dispatch(
//    apiCallBegan({
//      url: urlAPI + "AudioCompanyList/" + msg.audio_id + "/",
//      method: "put",
//      data: { Interactive_Rows: 'null' },
//      onStart: RequestStart.type,
//      onSuccess: AudioUpdateLinkedRequsted.type,
//      onError: RequestFailed.type,
//    })
//  );
//  dispatch(loadIMessageList());
//  dispatch(LinkToRowConnect(null));

//    }else  if (IMType === "Button") {

//          dispatch(
//            apiCallBegan({
//              url: urlAPI + "AudioCompanyList/" + msg.audio_id + "/",
//              method: "put",
//              data: { Interactive_Buttons: 'null' },
//              onStart: RequestStart.type,
//              onSuccess: AudioUpdateLinkedRequsted.type,
//              onError: RequestFailed.type,
//            })
//          );
//          dispatch(loadIMessageButtons());
//          dispatch(LinkToButtonConnect(null));
//        };

//   };
// };

// const filterByString = (value1, value2) => {
//   if (value2 !== "") {
//     console.log(value2);
//     if (value1) return value1.toLowerCase().includes(value2.toLowerCase());
//   } else {
//     console.log(value2);
//     return true;
//   }
// };
// const filterByBool = (value1, value2) => {
//   if (value2) {
//     console.log(value2);
//     console.log(value1);
//     if (value1) return true;
//     else return false;
//   } else {
//     console.log("true");
//     return true;
//   }
// };

// export const getMsgListFilter = createSelector(
//   (state) => state.entities.audios.AudioList,
//   (state) => state.entities.audios.filter,
//   (AudioList, filter) =>
//     AudioList && AudioList.results
//       ? AudioList.results.filter(
//           (a) =>
//             a.is_delete === false &&
//             filterByString(a.name, filter.IMName) &&
//             filterByString(a.keyword, filter.IMKeyword) &&
//             filterByBool(a.Interactive_Rows, filter.IMRowConnected)
//         )
//       : AudioList
// );

// export const getFilter = createSelector(
//   (state) => state.entities.audios.filter,

//   (filter) => filter
// );

// export const getMsgList = createSelector(
//   (state) => state.entities.audios,
//   (audios) =>
//     audios.AudioList && audios.AudioList.results
//       ? audios.AudioList.results.filter((a) => a.is_delete === false)
//       : audios.AudioList
// );

// export const getMediaAudio = createSelector(
//   (state) => state.entities.audios.Audio,
//   (Audio) => Audio
// );

// function findArrayElementByTitle(array, elem) {
//   return array.find((element) => {
//     return element.elem;
//   });
// }

export const getLoadMsgs = createSelector(
  (state) => state.entities.messages.MsgList,

  (MsgList) => MsgList
);

export const getListMsgs_current = createSelector(
  (state) => state.entities.messages.CurrentMsgList,

  (CurrentMsgList) => CurrentMsgList
);

const checkfilter = (msgs, filter) => {
  // date: "",
  // datecond: "",
  // msgLengthVal: 0,
  // msgLengthCond:"",
  if (filter.date && filter.datecond) {

    msgs = msgs.filter((a) =>
      filter.date !== "" && filter.datecond && a.mo_date ?
        filter.datecond === "eq"
          ? new Date(a["mo_date"]).getDate() === new Date(filter.date).getDate() &&
          new Date(a["mo_date"]).getFullYear() === new Date(filter.date).getFullYear() &&
          new Date(a["mo_date"]).getMonth() === new Date(filter.date).getMonth()
          : filter.datecond === "lt"
            ? new Date(a["mo_date"]).getDate() <= new Date(filter.date).getDate() &&
            new Date(a["mo_date"]).getFullYear() <= new Date(filter.date).getFullYear() &&
            new Date(a["mo_date"]).getMonth() <= new Date(filter.date).getMonth()
            : filter.datecond === "gt"
              ? new Date(a["mo_date"]).getDate() >= new Date(filter.date).getDate() &&
              new Date(a["mo_date"]).getFullYear() >= new Date(filter.date).getFullYear() &&
              new Date(a["mo_date"]).getMonth() >= new Date(filter.date).getMonth()
              : a
        : a
    );
  }
  if (filter.msgLengthVal && filter.msgLengthCond) {

    msgs = msgs.filter((a) =>
      filter.msgLengthVal !== 0 && filter.msgLengthCond && a.mo_message ?
        filter.msgLengthCond === "eq"
          ? a.mo_message.length === parseInt(filter.msgLengthVal)
          : filter.msgLengthCond === "lt"
            ? a.mo_message.length <= parseInt(filter.msgLengthVal)
            : filter.msgLengthCond === "gt"
              ? a.mo_message.length >= parseInt(filter.msgLengthVal)
              : a
        : a
    );

  }

  return msgs;
}

export const getLoadCurrentMsgs = createSelector(
  (state) => state.entities.messages.CurrentMsgList,
  (state) => state.entities.messages.filter,

  (CurrentMsgList, filter) => checkfilter(CurrentMsgList, filter)
);

export const getLoadDreams_Sent_Delete = createSelector(
  (state) => state.entities.messages.dreams_Sent_Delete,
  (state) => state.entities.messages.filter,

  (dreams_Sent_Delete, filter) => filter.list === "" ? dreams_Sent_Delete : filter.list === "محذوف" ? dreams_Sent_Delete.filter((a) => a.isdelete === true) : filter.list === "مرسل" ? dreams_Sent_Delete.filter((a) => a.isredytosent === true) : dreams_Sent_Delete
);

export const getLoadCurrentmsg = createSelector(
  (state) => state.entities.messages.CurrentMsg,

  (CurrentMsg) => CurrentMsg
);
export const getFilter = createSelector(
  (state) => state.entities.messages.filter,

  (filter) => filter
);
export const getloading = createSelector(
  (state) => state.entities.messages.loading,

  (loading) => loading
);
export const getMsgToSend = createSelector(
  (state) => state.entities.messages.MsgToSend,

  (MsgToSend) => MsgToSend
);

export const getMsgHistory = createSelector(
  (state) => state.entities.messages.msgHistory,

  (msgHistory) => msgHistory
);
export const getMsgToSent = createSelector(
  (state) => state.entities.messages.MsgToSent,

  (MsgToSent) => MsgToSent
);
export const getMessageHistory = createSelector(
  (state) => state.entities.messages.ClientMsgListHistory,

  (ClientMsgListHistory) => ClientMsgListHistory
);



export const getMsgStatus = createSelector(
  (state) => state.entities.messages.CurrentMsgList,

  (CurrentMsgList) => [...new Set(CurrentMsgList.map((item) => item.mt_arabic))]
);
