import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import ReactEncrypt from "react-encrypt";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "user",

  initialState: {
    G: "",
    user: "",
    token: "",
    userslist: [],
    wa: "",
    id: "",
    loading:false,
  },
  reducers: {
    // action => action handler
    userAuth: (user, action) => {
      console.log(action.payload.data.data);
      user.G = action.payload.data.data.gro;
      user.user = action.payload.data.data.user;
      user.token = action.payload.data.data.token;
      user.wa = action.payload.data.data.wa;
      user.id = action.payload.data.data.id;

      // if (action.payload.token) {
      //   localStorage.setItem(tokenKey, token.data.token);
      //   const encodedString = new Buffer(username + ":" + password).toString(
      //     "base64"
      //   );
      // localStorage.setItem("token", action.payload.token);

      //}
    },
    exit_LogOut: (user, action) => {
      localStorage.removeItem("G");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("user");
      localStorage.removeItem("wa");
      localStorage.removeItem("id");
    },
    ReturnStorage: (user, action) => {
      user.navBar.ShowLastStorage = !user.navBar.ShowLastStorage;
      if (user.navBar.IconShowLastStorage === "fa fa-envelope-o") {
        user.navBar.IconShowLastStorage = "fa fa-envelope-open";
      } else {
        user.navBar.IconShowLastStorage = "fa fa-envelope-o";
      }
      const LastReply = localStorage.getItem("LastReply");
      user.navBar.ReplyFromStorage = LastReply;
    },
    ShowHideNotes: (user, action) => {
      user.navBar.ShowNotes = !user.navBar.ShowNotes;
      if (user.navBar.IconShowNotes === "fa fa-eye") {
        user.navBar.IconShowNotes = "fa fa-eye-slash";
      } else {
        user.navBar.IconShowNotes = "fa fa-eye";
      }
    },
    ShowHideRep: (user, action) => {
      user.navBar.ShowHideRep = !user.navBar.ShowHideRep;
      if (user.navBar.IconShowRep === "fa fa-building") {
        user.navBar.IconShowRep = "fa fa-building-o";
      } else {
        user.navBar.IconShowRep = "fa fa-building";
      }
    },
    ShowHideReplyedDream: (user, action) => {
      user.navBar.ShowHideReplyedDream = !user.navBar.ShowHideReplyedDream;
      if (user.navBar.IconReplyedDream === "fa fa-calendar") {
        user.navBar.IconReplyedDream = "fa fa-calendar-o";
      } else {
        user.navBar.IconReplyedDream = "fa fa-calendar";
      }
    },
    LoadUserList: (user, action) => {
      user.userslist=action.payload;
    },

    RequestStart: (user, action) => {
      user.loading = true;
    },
    RequestFinish: (user, action) => {
      user.loading = false;
    },
    RequestFailed: (user, action) => {
      user.loading = false;
    },
  },
});

export const {
  userAuth,
  RequestStart,
  RequestFailed,
  RequestFinish,
  exit_LogOut,
  ReturnStorage,
  ShowHideNotes,
  ShowHideRep,
  ShowHideReplyedDream,
  LoadUserList,
} = slice.actions;

export default slice.reducer;
const apiEndpoint =  "token";

// export const login = (data) => (dispatch, getState) => {
 
//   const auth =JSON.stringify({"username": data.data.username,
//   "password": data.data.password});
//  console.log(auth);
//   return dispatch(
//     apiCallBegan({
//       url: apiEndpoint,
//       method: "post",
//       data: auth,
//       onStart: RequestStart.type,
//       onSuccess: userAuth.type,
//       onError: RequestFailed.type,
//     })
//   );
// };

export const addUserInfo = (data) => (dispatch, getState) => {
  return dispatch(
    userAuth({
      data
    })
  );
};

export const loaduserlistfromDB = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "user/l/",
      onStart: RequestStart.type,
      onSuccess: LoadUserList.type,
      onError: RequestFailed.type,
    })
  );
};


export const getUserAuth = createSelector(
  (state) => state.entities.user,

  (user) => user,
);
export const G = createSelector(
  (state) => state.entities.user.G,

  (G) => G,
);

export const getUserList = createSelector(
  (state) => state.entities.user.userslist,
  (userslist) => userslist.filter((user)=> user!=="admin")
);

export const LOGOUT_Clear = () => (dispatch, getState) => {
  localStorage.setItem("G","");
  localStorage.setItem("token","");
  localStorage.setItem("user","");
  localStorage.setItem("wa", "");
  localStorage.setItem("id", "");
 // localStorage.setItem("user","");
  window.location = "/";
};


