import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userAuth } from "../store/user";
import Table from "./table/table";
import TextareaAutosize from "react-textarea-autosize";
import badwords from "./bad.json";
import {
  ChangeHistpryMobile,
  DreamRelease,
  DreamRemoveUserReply,
  EditMsg,
  
  ChangeStatus,
  GetClientMsgList,
  
  LockedDream,
  DeleteDream,
  MODreamUpdateDB,
} from "../store/messages/messages";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { paginate } from "../utils/paginate";
import UserList from "./common/userslist";
import Moment from "react-moment";
import UserReply from "./common/userReply";
import {
  Clipboard2Data,
  Trash,
  Trash3Fill,
  SendCheck,
  EnvelopeCheck,
  EnvelopeOpen,
  EnvelopePaper,
  Bell,
  BellSlashFill,
  EnvelopePaperFill,
  SendFill,
  SendExclamationFill,
  SendCheckFill,
} from "react-bootstrap-icons";
import SCNo from "./common/scNo";
import ListOfStatus from "./common/listOfStatus";
import KindOfCustomer from "./common/kindOfCustomer";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import MessageCount from "./common/messageCount";

export default function AuditorDataTable({
  dreams,
  handleChangeInput,
  pageno,
  pagesize,
  ChangeMobileNO,
  isFilter,
  sendSocket
}) {
  const dispatch = useDispatch();
  const [isbword, setIsbword] = useState(false);
  const [bworddream, setBworddream] = useState("");
  const [replyid, setReplyid] = useState(0);
  const [value, setValue] = useState("");
 let msgListdata = dreams;
if(!isFilter){  msgListdata = paginate(dreams, pageno, pagesize);}

console.log(msgListdata);
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
      ["clean"],
    ],
  };

  const styleMO = {
    color: "blue",

    fontStyle: "italic",
  };
  const handleGetALLMSG = (mobile) => {};
  const ChangeMOFormat = (dream) => (value) => {
    let newdream = { ...dream };
    newdream["mo_message_html"] = value;
    dispatch(EditMsg(newdream));
  
  };
  const HandleMobileHistory = (mob) => {
    dispatch(ChangeHistpryMobile(mob));

    //dispatch(GetClientMsgList());
     
    window.open("/History", "_blank");
  };
  const handleRelease = (dream) => {
    dispatch(DreamRelease(dream));
  };
  const removeUserReply = (dream) => {
       const newdream = { ...dream };
       newdream["replyusername"] = "";
      
       dispatch(EditMsg(newdream));
    
  };

  const checkbadwords = (msg) => {
    const wordsArray = badwords.badwords;
    const yourString = msg.trim();
    let isfoundbad = false;

    const result = wordsArray.map((w) => {
      if (yourString.split(/\s+/).includes(w, 0)) {
        isfoundbad = true;
      }
    });

    return isfoundbad;
  };

  const undelete=(dream)=>{


      confirmAlert({
        title: "اعادة الحلم",
        message: "هل ترغب بفك الحذف واعادة الحلم؟",
        buttons: [
          {
            label: "نعم",
            onClick: () => {
              let newdream = { ...dream };
              newdream["isdelete"] = false;

              dispatch(EditMsg(newdream));
              dispatch(DeleteDream());
            },
          },
          {
            label: "لا",
            onClick: () => {},
          },
        ],
      }); 

  
  }

  const handleDreamSent =(dream)=>{
    let newdream = { ...dream };
    newdream["isreply"] =true;
    newdream["isredytosent"] =false;
    dispatch(MODreamUpdateDB(newdream));
    sendSocket(newdream);
  }
  const handleDreamStopSent=(dream)=>{
    let newdream = { ...dream };
    newdream["isreply"] =true;
    newdream["isredytosent"] =false;
    dispatch(MODreamUpdateDB(newdream));
    
  }
  const handleDreamSentDirect=(dream)=>{
    let newdream = { ...dream };
    newdream["isreply"] =true;
    newdream["isredytosent"] =true;
    dispatch(MODreamUpdateDB(newdream));
    sendSocket(newdream);
  }

  const SendUpdateMsgAPI=(dream)=>{
//
dispatch(MODreamUpdateDB(dream));

  }
  const saveUpdateMO=(dream)=>{
    //
    dispatch(SendUpdateMsgAPI(dream))
    sendSocket(dream);
    
      }
  const deletemsg=(dream)=>{


    confirmAlert({
      title: "حذف الحلم",
      message: "هل ترغب بحذف الحلم الحلم؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            let newdream = { ...dream };
            newdream["isdelete"] =true;

            dispatch(EditMsg(newdream));
            dispatch(DeleteDream());
            //
          },
        },
        {
          label: "لا",
          onClick: () => {},
        },
      ],
    }); 


}

const optoutmsg=(dream)=>{


  confirmAlert({
    title: "حذف الرقم من الترويج",
    message: "هل ترغب بحذف الرقم من التريج؟ ",
    buttons: [
      {
        label: "نعم",
        onClick: () => {
          let newdream = { ...dream };
          let newmobile_details = { ...newdream["mobile_details"] };
          newmobile_details["optout"] =true;
          newdream["mobile_details"]=newmobile_details;
          console.log(newdream);

          dispatch(EditMsg(newdream));
          //dispatch(DeleteDream());
          //
        },
      },
      {
        label: "لا",
        onClick: () => {},
      },
    ],
  }); 


}

const optinmsg=(dream)=>{


  confirmAlert({
    title: "إعادة الرقم إلى الترويج",
    message: "هل ترغب بإعادة الرقم إلى التريج؟ ",
    buttons: [
      {
        label: "نعم",
        onClick: () => {
          let newdream = { ...dream };
          let newmobile_details = { ...newdream["mobile_details"] };
          newmobile_details["optout"] =false;
          newdream["mobile_details"]=newmobile_details;
          console.log(newdream);
          dispatch(EditMsg(newdream));
          //dispatch(DeleteDream());
          //
        },
      },
      {
        label: "لا",
        onClick: () => {},
      },
    ],
  }); 


}

  const checkSentResult=(result)=>{
    if( result && result.includes("MessageID=")){
      return true;
    }else {
      return false;
    }

  }
  const changebadwords = (msg) => {
    const wordsArray = badwords.badwords;
    let yourString = msg.trim();
    const result = wordsArray.map((w) => {
      if (yourString.split(/\s+/).includes(w)) {
        yourString = yourString.replace(
          w,
          "<span   style=" + '"color:red"' + ">" + w + "</span>"
        );
        console.log(w);
      }
    });
    return yourString;
  };
  const handleChangeInputLocal =
    (dream) =>
    ({ currentTarget: input }) => {
      if (checkbadwords(input.value)) {
        setIsbword(true);
        setReplyid(dream.replyid);
        setBworddream(changebadwords(input.value));
      } else {
        setIsbword(false);
        setReplyid(0);
        setBworddream(input.value);
      }

      const newdream = { ...dream };
      newdream[input.name] = input.value;
      if (newdream["replyusername"]==="") {
        const user = localStorage.getItem("user");

        if(user) {
          newdream["replyusername"]=user;
         
        
        }
        else {alert("user deleted info please login again after save");}

      }
      
      newdream["mt_date"] = new Date();
      dispatch(EditMsg(newdream));
    };
  const handleLockedUser = (dream) => (event) => {
    console.log(dream);
    console.log(event.target.value);
    const userlocked = event.target.value;
  };

  const handleChangeStatus =
    (dream) =>
    ({ currentTarget: input }) => {
      const newdream = { ...dream };
      newdream[input.name] = input.value;
      dispatch(EditMsg(newdream));
    };
    

    const handlePersonalStatus =
    (dream) =>
    ({ currentTarget: input }) => {
      console.log(input);
      let newdream = { ...dream };
      let newmobile_details = { ...newdream["mobile_details"] };
      newmobile_details[input.name] =input.value;
      newdream["mobile_details"]=newmobile_details;
 
      dispatch(EditMsg(newdream));
    };



    const handleChangeLocked =
    (dream) =>
    ({ currentTarget: input }) => {

      const newdream = { ...dream };
if(input.value===""){
  console.log(input.value);
  newdream["userlock"] = input.value;
      newdream["islock"] = false;
      newdream["userlockdate"] = null;
 
}else {
  console.log(input.value);
  newdream["userlock"] = input.value;
  newdream["islock"] = true;
  newdream["userlockdate"] = new Date();
}
      

      dispatch(EditMsg(newdream));
      dispatch(LockedDream());
      sendSocket(newdream);

      //
    };

    // const handleChangeListStatus=(dream)=> ({ currentTarget: input }) => {
    
    // }
    // const handleChangeCustomerKind =
    //   (dream) =>
    //   ({ currentTarget: input }) => {};

  let columns = [
    {
      colwidth: "20%",
      key: 1,
      path: "personstatus",
      label: "الحالة",
      content: (dream) => (
        <div
          className={
            dream.moshortcode === 4022
              ? " text-right row4022color"
              : dream.moshortcode === 2337
              ? "text-right text-primary"
              : dream.moshortcode === 6877
              ? "text-right row6877color"
              : dream.moshortcode === 4024
              ? "text-right row4024color"
              : dream.moshortcode === 3900
              ? "text-right row3900color"
              : dream.moshortcode === 2304
              ? "text-right row2304color"
              : dream.moshortcode === 6055
              ? "text-right row2304color"
              : dream.moshortcode === 4142
              ? "text-right row4142color"
              : dream.moshortcode === 4060
              ? "text-right row4060background-color"
              : ""
          }
        >
          {dream.isdelete ? 
            <Trash3Fill
              size={30}
              className="m-1 cursor-pointer text-danger"
              onClick={() => undelete(dream)}
            >
              {" "}
            </Trash3Fill>
            : <Trash
            size={30}
            className="m-1 cursor-pointer "
            onClick={() => deletemsg(dream)}
          >
            {" "}
          </Trash>
          }
          {dream.isredytosent && (
            <EnvelopePaperFill
              size={30}
              className="m-1 cursor-pointer text-warning"
            ></EnvelopePaperFill>
          )}
          {dream.issent && checkSentResult(dream.mt_resoult) ? (
            <SendCheckFill
              size={30}
              className="m-1 cursor-pointer text-success"
            ></SendCheckFill>
          ) : (
            <SendExclamationFill
              size={30}
              className="m-1 cursor-pointer text-danger"
            ></SendExclamationFill>
          )}
             <UserList
            dream={dream}
            id="userlock"
            key={"userlocked" + dream.replyid}
            handleChange={handleChangeLocked}
            label="حجز أو فك حجز لمفسر"
          ></UserList>
          <button
            onClick={() => saveUpdateMO(dream)}
            className="btn btn-outline-primary form-control float-end font-weight-bold"
          >
            حفظ
          </button>{" "}
          <KindOfCustomer
            dream={dream}
            optinmsg={optinmsg}
            optoutmsg={optoutmsg}

            handleChangeCustomerKind={handlePersonalStatus}
          ></KindOfCustomer>
       
          {dream.userlock && (
            <ListOfStatus
              dream={dream}
              handleChangeStatus={handleChangeStatus}
            ></ListOfStatus>
          )}
          <SCNo dream={dream} dreams={dreams}></SCNo>
          <TextareaAutosize
            minRows={3}
            className={
              dream.moshortcode === 4022
                ? "text-right row4022color"
                : dream.moshortcode === 2337
                ? "text-right row2337color"
                : dream.moshortcode === 6877
                ? "text-right row6877color"
                : dream.moshortcode === 4024
                ? "text-right row4024color"
                : dream.moshortcode === 3900
                ? "text-right row3900color"
                : dream.moshortcode === 2304
                ? "text-right row2304color"
                : dream.moshortcode === 6055
                ? "text-right row6055color"
                : dream.moshortcode === 4142
                ? "text-right row4142color"
                : dream.moshortcode === 4060
                ? "text-right row4060background-color"
                : ""
            }
            style={{ direction: "rtl" }}
            rows={3}
            id={"personstatus" + dream.replyid}
            name="personstatus"
            placeholder="كتابة الحالة"
            defaultValue={dream.mobile_details && dream.mobile_details.personstatus ? dream.mobile_details.personstatus : ""}
            onChange={handlePersonalStatus(dream)}
          />
          <TextareaAutosize
            minRows={3}
            className={
              dream.moshortcode === 4022
                ? "mt-1  text-right row4022color"
                : dream.moshortcode === 2337
                ? "mt-1 text-right row2337color"
                : dream.moshortcode === 6877
                ? "mt-1 text-right row6877color"
                : dream.moshortcode === 4024
                ? "mt-1 text-right row4024color"
                : dream.moshortcode === 3900
                ? "mt-1 text-right row3900color"
                : dream.moshortcode === 2304
                ? "mt-1 text-right row2304color"
                : dream.moshortcode === 6055
                ? "mt-1 text-right row6055color"
                : dream.moshortcode === 4142
                ? "mt-1 text-right row4142color"
                : dream.moshortcode === 4060
                ? "text-right row4060background-color"
                : ""
            }
            style={{ direction: "rtl" }}
            rows={3}
            placeholder="كتابة أرقام الموبايلات المرتبطة"
            id={"mobilerelated" + dream.replyid}
            name="mobilerelated"
            defaultValue={
              dream.mobile_details&& dream.mobile_details.personstatusmobile ? dream.mobile_details.personstatusmobile : ""
            }
            onChange={handlePersonalStatus(dream)}
          />
          <br></br>
          <UserReply
            dream={dream}
            handleChangeNotes={handleChangeStatus}
            handleRelease={handleRelease}
            removeUserReply={removeUserReply}
          ></UserReply>
        </div>
      ),
    },
    {
      colwidth: "40%",
      key: 2,
      path: "mo_message",
      label: "الحلم",
      content: (dream) => (
        <React.Fragment>
          <div>
            <div className="font-weight-bold m-2 fs-5">
              {" "}
              <button
                onClick={() => ChangeMobileNO(dream.mt_mobil)}
                className="btn btn-outline-dark float-end font-weight-bold"
              >
                {parseInt(dream.mt_mobil) + parseInt(971507354835)}
              </button>
              <Moment
                parse="YYYY-MM-DD"
                format="YYYY-MM-DD"
                className="text-success float-start font-weight-bold p-2"
              >
                {dream.mo_date}
              </Moment>
            </div>
            <div className="font-weight-bold m-2 fs-5">
              <Moment
                parse="HH:mm:ss"
                format="hh:mm:ss A"
                className="text-success float-start font-weight-bold p-2"
              >
                {dream.mo_date}
              </Moment>
              <div className="float-end ">
                {" "}
                <Clipboard2Data
                  size={25}
                  onClick={() => HandleMobileHistory(dream.mt_mobil)}
                  className="m-1 cursor-pointer text-success"
                ></Clipboard2Data>
              </div>
            </div>
          </div>
          <br></br>

          <br></br>
          <div className="m-1">
            <div className="text-editor">
              <ReactQuill
                value={
                  dream.mo_message_html
                    ? dream.mo_message_html
                    : dream.mo_message
                }
                heme="snow"
                modules={modules}
                className={
                  dream.moshortcode === 4022
                    ? " text-right row4022color"
                    : dream.moshortcode === 2337
                    ? "text-right row2337color"
                    : dream.moshortcode === 6877
                    ? "text-right row6877color"
                    : dream.moshortcode === 4024
                    ? "text-right row4024color"
                    : dream.moshortcode === 3900
                    ? "text-right row3900color"
                    : dream.moshortcode === 2304
                    ? "text-right row2304color"
                    : dream.moshortcode === 6055
                    ? "text-right row6055color"
                    : dream.moshortcode === 4142
                    ? "text-right row4142color"
                    : dream.moshortcode === 4060
                    ? "text-right row4060background-color"
                    : ""
                }
                onChange={ChangeMOFormat(dream)}
              />
            </div>
          </div>
        </React.Fragment>
      ),
    },
    {
      colwidth: "40%",
      key: 3,
      path: "mt_reply_msg",
      label: "التفسير",
      content: (dream) => (
        <React.Fragment>
          {isbword && dream.replyid === replyid && (
            <div dangerouslySetInnerHTML={{ __html: bworddream }}></div>
          )}
          <button
            type="button"
            onClick={() => handleDreamSent(dream)}
            className="btn btn-outline-success float-start m-2"
          >
            ارسال الحلم
          </button>
          <button
            type="button"
            onClick={() => handleDreamSentDirect(dream)}
            className="btn btn-outline-primary float-end m-2"
          >
            ارسال الحلم مباشرة{" "}
          </button>
          <div className="font-weight-bold m-2 fs-5">
          <Moment
                parse="YYYY-MM-DD"
                format="YYYY-MM-DD"
                className="text-primary float-start font-weight-bold p-2"
              >
                 {dream.replydate}
              </Moment>
            </div>
            <div className="font-weight-bold m-2 fs-5">
              <Moment
                parse="HH:mm:ss"
                format="hh:mm:ss A"
                className="text-primary float-start font-weight-bold p-2"
              >
                {dream.replydate}
              </Moment>
              </div>
          <TextareaAutosize
            minRows={3}
            className="textareastyle"
            style={{ direction: "rtl" }}
            rows={3}
            id={"mt_reply_msg" + dream.replyid}
            name="mt_reply_msg"
            defaultValue={dream.mt_reply_msg ? dream.mt_reply_msg : ""}
            onChange={handleChangeInputLocal(dream)}
          />
           <br></br>
        
  
          <div className="font-weight-bold m-2 fs-5">
          <MessageCount msg={dream.mt_reply_msg ? dream.mt_reply_msg : "" }></MessageCount>
          <Moment
                parse="YYYY-MM-DD"
                format="YYYY-MM-DD"
                className="text-white bg-primary float-start font-weight-bold p-2"
              >
                 {dream.mt_date}
              </Moment>
            </div>
            <div className="font-weight-bold m-2 fs-5">
              <Moment
                parse="HH:mm:ss"
                format="hh:mm:ss A"
                className="text-white bg-primary float-start font-weight-bold p-2"
              >
                {dream.mt_date}
              </Moment>
              </div>
         {dream.isredytosent && !dream.issent &&  <button
            type="button"
            onClick={() => handleDreamStopSent(dream)}
            className="btn btn-outline-info form-control float-end m-2 p-2"
          >
            ايقاف الإرسال{" "}
          </button>}
        </React.Fragment>
      ),
    },
  ];

  return <Table columns={columns} data={msgListdata} idkey="idkey" />;
}
