import React from 'react';
import sclist from "../sc.json";

function SCListFilter({setSelectedSC,selectedSC}) {
      return (
        <div className="m-2">
          <select
            onChange={(e) => setSelectedSC(e.target.value)}
            name="userfilter"
            id="userfilter"
            className="m-1"
          >
            <option value="" key="0">
              الكل
            </option>
            {sclist.sclist.map((sclist, index) => (
              <option
                value={sclist.sc}
                key={sclist.sc}
                className={"badge-" + sclist.sc}
              >
                {sclist.sc} {"  -  "} {sclist.details}
              </option>
            ))}
          </select>
        </div>
      );
}

export default SCListFilter;