import React from "react";
import { Route, Outlet, Navigate } from "react-router-dom";
import auth from "../../services/authService";
 
 

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.getCurrentUser())
          return (
            <Navigate
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
              replace
            />
          );
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;



export const PrivateRoute = (user) => {
  // determine if authorized, from context or however you're doing it
 
  // If authorized, return an outlet that will render child elements
  // If nottoken, return element that will navigate to login page
  user = user ? user : localStorage.getItem("user");
  return user ? <Outlet /> : <Navigate to="/login" />;
};
