import React from "react";
import { Bell, BellSlashFill } from "react-bootstrap-icons";
import { DreamRelease } from "../../store/messages/messages";

function KindOfCustomer({ dream, handleChangeCustomerKind,optinmsg,optoutmsg }) {
  const List = [
    "عادي",
    "أحلامه كثيرة جداً",
    "مهم جداً",
    "عاجل",
    "عجول",
    "عجول جداً",
    "خدمة التفسير سريع",
    "للحذف الفوري",
    "ثرثرة وسيئ",
  ];
  return (
    <div>
      <div className="m-2">
       <h3 >{dream.moshortcode} {"   "} 
       {!dream.mobile_details.optout?<Bell   size={30} onClick={(e)=> optoutmsg(dream)}
              className="m-1 float-start cursor-pointer text-success"></Bell>:<BellSlashFill   size={30}
              className="m-1  float-start cursor-pointer text-danger" onClick={(e)=> optinmsg(dream)}></BellSlashFill>}</h3> 
       
        {"  "}
        <select
        onChange={handleChangeCustomerKind(dream)}
         
          name="kindofcustomer"  
          id={"kindofcustomer" + dream.replyid}
          className="m-1 selectStatusList"
        >
          <option value="" key="0">
            نوع العميل
          </option>
          {List.map((list, index) => (
            <option key={"kindofcustomer" + index + dream.replyid} value={list}>
              {list}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default KindOfCustomer;
