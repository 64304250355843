import React from 'react';

function SCNo({ dreams, dream }) {
  return (
    <div className="d-flex flex-row p-1">
      <h4 className="ml-1 pl-1 ">
        {" "}
        <span
          className="badge rounded-pill    badge-4022"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="4022"
        >
          {" "}
          {
            dreams.filter(
              (x) => x.mt_mobil === dream.mt_mobil && x.moshortcode === 4022
            ).length
          }
        </span>{" "}
      </h4>
      <h4 className="ml-1 pl-1 ">
        {" "}
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="4024"
          className="badge rounded-pill  ml-1 pl-1   badge-4024"
        >
          {
            dreams.filter(
              (x) => x.mt_mobil === dream.mt_mobil && x.moshortcode === 4024
            ).length
          }
        </span>{" "}
      </h4>
      <h4 className="ml-1 pl-1 ">
        {" "}
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="3900"
          className="badge rounded-pill  ml-1 pl-1   badge-3900"
        >
          {
            dreams.filter(
              (x) => x.mt_mobil === dream.mt_mobil && x.moshortcode === 3900
            ).length
          }
        </span>{" "}
      </h4>
      <h4 className="ml-1 pl-1 ">
        {" "}
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="2304"
          className="badge rounded-pill  ml-1 pl-1   badge-2304"
        >
          {
            dreams.filter(
              (x) => x.mt_mobil === dream.mt_mobil && x.moshortcode === 2304
            ).length
          }
        </span>{" "}
      </h4>
      <h4 className="ml-1 pl-1 ">
        {" "}
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="6877"
          className="badge rounded-pill  ml-1 pl-1   badge-6877"
        >
          {
            dreams.filter(
              (x) => x.mt_mobil === dream.mt_mobil && x.moshortcode === 6877
            ).length
          }
        </span>{" "}
      </h4>
      <h4 className="ml-1 pl-1 ">
        {" "}
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="6055"
          className="badge rounded-pill  ml-1 pl-1   badge-6055"
        >
          {
            dreams.filter(
              (x) => x.mt_mobil === dream.mt_mobil && x.moshortcode === 6055
            ).length
          }
        </span>{" "}
      </h4>
      <h4 className="ml-1 pl-1 ">
        {" "}
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="2337"
          className="badge rounded-pill  ml-1 pl-1   badge-2337"
        >
          {
            dreams.filter(
              (x) => x.mt_mobil === dream.mt_mobil && x.moshortcode === 2337
            ).length
          }
        </span>{" "}
      </h4>{" "}
      <h4 className="ml-1 pl-1 ">
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="4142"
          className="badge rounded-pill  ml-1 pl-1   badge-4142"
        >
          {
            dreams.filter(
              (x) => x.mt_mobil === dream.mt_mobil && x.moshortcode === 4142
            ).length
          }
        </span>{" "}
      </h4>
    </div>
  );
}

export default SCNo;