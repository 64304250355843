import React from 'react';
import { useSelector } from "react-redux";
import { getMsgStatus } from '../../store/messages/messages';
function DreamStatus(props) {
        const status = useSelector(getMsgStatus);
        console.log(props.msgStatus);

      return (
        <div>
          <div className="m-2">
            <select
              onChange={(e) => props.setMsgStatus(e.target.value)}
              name="statusfilter"
              id="statusfilter"
              className="m-1"
            >
              <option value="" key="0">
                الحالة
              </option>
              {status.map((stat, index) => (
                <option key={"status" + index} value={stat}>
                  {stat}
                </option>
              ))}
            </select>
          </div>
        </div>
      );
}

export default DreamStatus;