import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { XCircleFill } from "react-bootstrap-icons";
import Moment from "react-moment";
export default function  UserReply ({
  dream,
  handleChangeNotes,
  handleRelease,
  removeUserReply,
}){
  return (
    dream.replyusername ? (
      <div className="justify-content-center m-2">
        {dream.replyusername}
        <XCircleFill
          size={20}
          className="m-1 cursor-pointer text-danger"
          onClick={() => removeUserReply(dream)}
        ></XCircleFill>
        <br></br>
        {dream.islock && (
          <Moment
            parse="YYYY-MM-DD HH:mm"
            format="YYYY-MM-DD hh:mm A"
            className="text-primary"
          >
            {dream.userlockdate}
          </Moment>
        )}
        <br></br>
        <TextareaAutosize
          minRows={3}
          className="form-control"
          style={{ direction: "rtl" }}
          rows={3}
          id={"notes" + dream.replyid}
          name="notes"
          placeholder="كتابة الملاحظة للمفسر"
          defaultValue={dream.notes ? dream.notes : ""}
          onChange={handleChangeNotes(dream)}
        />
        <br></br>
        {/* <button
          type="button"
          onClick={() => handleRelease(dream)}
          className="btn btn-outline-primary form-control"
        >
          فك وارسال ملاحظات
        </button> */}
      </div>
    ):  <div> <br></br>
    <TextareaAutosize
      minRows={3}
      className="form-control"
      style={{ direction: "rtl" }}
      rows={3}
      id={"notes" + dream.replyid}
      name="notes"
      placeholder="كتابة الملاحظة للمفسر"
      defaultValue={dream.notes ? dream.notes : ""}
      onChange={handleChangeNotes(dream)}
    />
    <br></br>
</div>  );
}

 
