import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserAuth, userAuth } from "../store/user";
import Table from "./table/table";
import TextareaAutosize from "react-textarea-autosize";
import badwords from "./bad.json";
import { ChangeHistpryMobile, EditMsg } from "../store/messages/messages";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Clipboard2Data } from "react-bootstrap-icons";
import { paginate } from "../utils/paginate";

export default function MainDataTable({ dreams, pageno, pagesize }) {
  const dispatch = useDispatch();
  const [isbword, setIsbword] = useState(false);
  const [bworddream, setBworddream] = useState("");
  const [replyid, setReplyid] = useState(0);
  const user = localStorage.getItem("user");
  let msgListdata = dreams;
  msgListdata = paginate(dreams, pageno, pagesize); 

  const checkbadwords = (msg) => {
    const wordsArray = badwords.badwords;
    const yourString = msg.trim();
    let isfoundbad = false;

    const result = wordsArray.map((w) => {
      if (yourString.split(/\s+/).includes(w, 0)) {
        isfoundbad = true;
      }
    });

    return isfoundbad;
  };
  const changebadwords = (msg) => {
    const wordsArray = badwords.badwords;
    let yourString = msg.trim();
    const result = wordsArray.map((w) => {
      if (yourString.split(/\s+/).includes(w)) {
        yourString = yourString.replace(
          w,
          "<span   style=" + '"color:red"' + ">" + w + "</span>"
        );
        console.log(w);
      }
    });
    return yourString;
  };
  const handleChangeInputLocal =
    (dream) =>
    ({ currentTarget: input }) => {
      if (checkbadwords(input.value)) {
        setIsbword(true);
        setReplyid(dream.replyid);
        setBworddream(changebadwords(input.value));
      } else {
        setIsbword(false);
        setReplyid(0);
        setBworddream(input.value);
      }
      const newdream = { ...dream };
      newdream[input.name] = input.value;
      newdream["mt_date"] = new Date();
      newdream["replyusername"] = user;
      if (input.value.length > 10) {
        newdream["isreply"] = true;

      }
      dispatch(EditMsg(newdream));
    };

  let columns = [
    {
      colwidth: "10%",
      key: 1,
      path: "personstatus",
      label: "الحالة",
      content: (dream) => (
        <div
          className={
            dream.moshortcode === 4022
              ? " text-right row4022color"
              : dream.moshortcode === 2337
              ? "text-right text-primary"
              : dream.moshortcode === 6877
              ? "text-right row6877color"
              : dream.moshortcode === 4024
              ? "text-right row4024color"
              : dream.moshortcode === 3900
              ? "text-right row3900color"
              : dream.moshortcode === 2304
              ? "text-right row2304color"
              : dream.moshortcode === 6055
              ? "text-right row2304color"
              : dream.moshortcode === 4142
              ? "text-right row2304color"
              : ""
          }
        >
          {/* <Clipboard2Data
            size={25}
            onClick={() => HandleMobileHistory(dream.mt_mobil)}
            className="m-1 cursor-pointer text-success"
          ></Clipboard2Data><br></br> */}
          {dream.personstatus}
        </div>
      ),
    },
    {
      colwidth: "40%",
      key: 2,
      path: "mo_message",
      label: "الحلم",
      content: (dream) => (
        <div
          className={
            dream.moshortcode === 4022
              ? " text-right row4022color"
              : dream.moshortcode === 2337
              ? "text-right text-primary"
              : dream.moshortcode === 6877
              ? "text-right row6877color"
              : dream.moshortcode === 4024
              ? "text-right row4024color"
              : dream.moshortcode === 3900
              ? "text-right row3900color"
              : dream.moshortcode === 2304
              ? "text-right row2304color"
              : dream.moshortcode === 6055
              ? "text-right row2304color"
              : dream.moshortcode === 4142
              ? "text-right row2304color"
              : ""
          }
          dangerouslySetInnerHTML={{
            __html: dream.mo_message_html
              ? dream.mo_message_html
              : dream.mo_message,
          }}
        ></div>
      ),
    },
    {
      colwidth: "50%",
      key: 3,
      path: "mt_reply_msg",
      label: "التفسير",
      content: (dream) => (
        <React.Fragment>
          {isbword && dream.replyid === replyid && (
            <div dangerouslySetInnerHTML={{ __html: bworddream }}></div>
          )}
          <TextareaAutosize
            minRows={3}
            className="form-control"
            style={{ direction: "rtl" }}
            rows={3}
            id={"mt_reply_msg" + dream.replyid}
            name="mt_reply_msg"
            defaultValue={dream.mt_reply_msg ? dream.mt_reply_msg : ""}
            onChange={handleChangeInputLocal(dream)}
          />
          <br>
          </br>
        
        </React.Fragment>
      ),
    },
  ];

  return <Table columns={columns} data={msgListdata} idkey="idkey" />;
}
