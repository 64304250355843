import React from "react";
import { Navigate } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../common/form";
import auth from "../../services/authService";
import logo from "../../img/Ahlamkom.png";
import { toast } from "react-toastify";
class LoginForm extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {},
  };

  stylebox = {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };
  schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;

      const token = await auth.Login(data.username, data.password);
      this.props.setToken(token);

      const { state } = this.props.location;
      if (data.username==="mona" || data.username==="admin"){ window.location = state ? state.from.pathname : "/Auditor";}
     else { window.location = state ? state.from.pathname : "/Main";}


    } catch (ex) {
 
        if (ex.response && ex.response.status === 404) {
          toast.error("خطأ بكلمة المرور أو اسم المستخدم")
        }
  }
  }
  render() {
    if (auth.getCurrentUser()==="mona" || auth.getCurrentUser()==="admin" ) return <Navigate replace to="/Auditor" />
else if  (auth.getCurrentUser()) return <Navigate replace to="/Main" />
    return (
      <div className="d-flex justify-content-center" style={this.stylebox}>
        <form
          className="shadow-lg p-3 mb-5 bg-white rounded"
          onSubmit={this.handleSubmit}
        >
          <div className="d-flex justify-content-center">
            <img
              alt="Ahlamkom"
              src={logo}
              className="rounded float-left avatar  mr-3  "
              style={{ height: "7.5vh" }}
            />
          </div>
          <div className="d-flex justify-content-center">
            <h2>تسجيل دخول </h2>
          </div>
          <div className="form-group m-2">
            {this.renderInput("username", "اسم المستخدم")}
          </div>
          <div className="form-group m-2">
            {this.renderInput("password", "كلمة المرور", "password")}
          </div>
          <div className="form-group m-2">{this.renderButton("Login")}</div>
        </form>
      </div>
    );
  }
}

export default LoginForm;
