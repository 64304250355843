import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const Pagination = ({
  itemsCount,
  pageSize,
  currentPage,
  onPageChange,
  isFilter,
}) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
 
  if (pagesCount === 1) return null;
  const pages = _.range(1, pagesCount + 1);
  if (!currentPage) onPageChange(1);
  if (currentPage > pagesCount) onPageChange(pagesCount);

  return (
    <nav>
      <ul className="pagination   flex-wrap  cursor-pointer">
        {pages.map((page) => (
          <li
            key={page}
            className={page === currentPage ? "page-item active" : "page-item"}
          >
            <a
              className="page-link"
              href="#/"
              onClick={() => onPageChange(page)}
            >
              {page}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default Pagination;
