import React from "react";
import _ from "lodash";

export default function TableBody({ data, columns, idkey }) {
  const renderCell = (item, column) => {
    if (column.content) return column.content(item);

    return _.get(item, column.path);
  };

  const createKey = (item, column,key) => {
  
   if (key){
    return item[idkey] + column + key;
   }else {
    return item[idkey] + column;
   }

  };

  return (
    <tbody>
      {data &&
        data.map((item) => (
      
          <tr key={createKey(item, "tr")}>
            {columns.map((column) => (
              <td
                key={createKey(item, "td", column.key)}
                width={column.colwidth}
              >
                {renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
    </tbody>
  );
}

 
