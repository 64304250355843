import React from "react";

const Select = ({ name, label, options,dream,handleChange, error, ...rest }) => {
  return (
    <div className="form-group ">
      <label htmlFor={name}>{label}</label>
      <select
        onChange={handleChange(dream)}
        name={name}
        id={name + dream.replyid}
        {...rest}
        className="m-1"
        value={dream.userlock !== "" && dream.userlock ? dream.userlock : ""}
      >
 
         <option key="1234User000"
          value=""
        ></option>
        {options.map((option) => (
          <option key={dream.replyid + option} value={option}>
            {option}
          </option>
        ))}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Select;
