import React, { useState } from "react";
import { useDispatch ,useSelector} from "react-redux";
import ReactDatePicker from "react-datepicker";
import SCListFilter from "./common/scListFilter";
import UserFilter from "./common/userFilter";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import DreamStatus from "./common/dreamstatus";
import { FilterChange, getFilter } from "../store/messages/messages";
import { Reception0, Reception4, Wifi, WifiOff } from "react-bootstrap-icons";
import { useEffect } from "react";
const AuditorNav = (props) => {
  const dispatch = useDispatch();
  const filter = useSelector(getFilter);
  const user = localStorage.getItem("user");
  const [startDate, setStartDate] = useState(new Date());
  const [isonline, setisonline] = useState(true);
  const dreamcount = 0;
  console.log(props.isfinish);
  const dreamnotsent = 1;
 const msgCount=({currentTarget})=>{
  props.setMsgLength( currentTarget.value )
 }
 const filterdispatcheddate=(val)=>{
  dispatch(FilterChange("date",val,startDate));
  setStartDate(val);
 }
 useEffect(() => {
  const timer = setInterval(() => {
    if (navigator.onLine) {
   
      setisonline(true)
     
    }else {
      setisonline(false)
    }

  }, 12000);
  return () => clearInterval(timer);
}, []);
 
  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-light w-100">
        <div className="container-fluid">
         {isonline? <Wifi    size={20}
          className="m-1 cursor-pointer text-success"></Wifi>:<WifiOff    size={20}
          className="m-1 cursor-pointer text-danger"></WifiOff>}
{props.readyState===1?<Reception4  size={20}
          className="m-1 cursor-pointer text-success"></Reception4>:<Reception0  size={20}
          className="m-1 cursor-pointer text-danger"></Reception0>}
          
          <h4>
            {"    "} أهلا بك {user} {"    "}
          </h4>

          <span className="navbar-text">
            لقد قمت اليوم بتفسير عدد{" "}
            <span className="badge bg-secondary">{dreamcount}</span>
            {"    "}
          </span>
          <span className="navbar-text">
            أحلام مفسره لم ترسل بعد{" "}
            <span className="badge bg-secondary">{dreamnotsent}</span>
          </span>
        </div>

        <div>{"   "}</div>
      </nav>
      {dreamnotsent && (
        <div className="list-group list-group-horizontal mb-4 mr-1 ml-2">
          {props.listButtons.map((item) => (
            <button
              onClick={() => props.handlelistButtons(item)}
              key={item["title"]}
              className={
                item["active"]
                  ? "list-group-item active bg-info"
                  : "list-group-item"
              }
            >
              {item["title"]}
            </button>
          ))}
          <DreamStatus
            msgStatus={props.msgStatus}
            setMsgStatus={props.setMsgStatus}
          ></DreamStatus>
          <UserFilter
            handleFilter={props.handleFilter}
            filterUser={props.filterUser}
          ></UserFilter>
          <SCListFilter
            selectedSC={props.selectedSC}
            setSelectedSC={props.setSelectedSC}
          ></SCListFilter>

          <select id="msglengthcond" className="m-1"  onChange={(e) => dispatch(FilterChange("msglengCond",e.target.value))}>
          <option value=""></option>
            <option value="eq">مساو</option>
            <option value="gt">أكبر</option>
            <option value="lt">أصغر</option>
          </select>

          <input
            type="text"
            style={{ width: "40px" }}
            id="Msglengthis"
            name="Msglength"
            className="m-1"
            value={filter.msgLengthVal}
            onChange={(e) => dispatch(FilterChange("msgleng",e.target.value))}
          ></input>
          <select id="datecond" className="m-1"  onChange={(e) => dispatch(FilterChange("datecond",e.target.value))}>
          <option value=""></option>
            <option value="eq">مساو</option>
            <option value="gt">أكبر</option>
            <option value="lt">أصغر</option>
          </select>
          <ReactDatePicker
            style={{ width: "20px" }}
            className="m-1 col-6"
            selected={startDate}
            onChange={filterdispatcheddate}
            dateFormat="dd-MM-yyyy"
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default AuditorNav; 
