import { combineReducers } from "redux";
 
import userReducer from "./user";
 
import ui_wpReducer from "./UI/ui_wp";
 
import messagesReducer from "./messages/messages";
 
export default combineReducers({
  user: userReducer,
  ui_wp: ui_wpReducer,
  messages: messagesReducer
  
});
