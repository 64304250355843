import React from 'react';
import {XCircleFill  } from "react-bootstrap-icons";

function Mobile(props) {
      return (
        props.mobileno && (
          <div className="d-flex justify-content-center">
            {parseInt(props.mobileno) + parseInt(971507354835)}
            <XCircleFill
              size={20}
              className="m-1 cursor-pointer"
              onClick={() => props.removeMobile("")}
            ></XCircleFill>
          </div>
        )
      );
}

export default Mobile;