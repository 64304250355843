import React, { useEffect,useState } from 'react';
import { useDispatch ,useSelector } from 'react-redux';
import { FilterListChange, GetClientMsgList, getFilter, getLoadDreams_Sent_Delete, getMessageHistory, LoadSentDeleteDreams } from '../store/messages/messages';
import SearchBox from './common/searchBox';
import HistoryDataTable from './historyDataTable';
import _ from "lodash";
import Pagination from '../utils/pagination';
import { paginate } from '../utils/paginate';
import ListMsgDataTable from './listMsgDataTable';
export default function ListMsg() {
    const dispatch = useDispatch();
    const dreams =  useSelector(getLoadDreams_Sent_Delete);
    //
    const filter =  useSelector(getFilter);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
  
     


      useEffect(() => {
        dispatch(LoadSentDeleteDreams())
        const timer = setInterval(() => {
       
          dispatch(LoadSentDeleteDreams())
      
        }, 18000);
        return () => clearInterval(timer);
      },[]);


   
      const ChangeCurrentpage=(cpage)=>{
            setCurrentPage(cpage);
      }
      let dreamtotranslate = _.orderBy(
            dreams,
            ["mt_date"],
            ["desc"]
          );
          dreamtotranslate = dreamtotranslate.filter((m) =>
          search ?m["mo_message"] && m["mt_reply_msg"]?m["mo_message"].includes(search)||
          m["mt_reply_msg"].includes(search):m["mo_message"] &&m["mo_message"].includes(search)
           : m
          );
         let dreampaination   = paginate(dreamtotranslate, currentPage, 20);
         
         const List = [
            "محذوف",
            "مرسل",
           
             
          ];
      
      return (
            <div>  
                   <select
          onChange={(e) => dispatch(FilterListChange(e.target.value))}
          name={"ListStatus2"}
          id={"ListStatus4"}
          className="m-1 selectStatusList"
        value={filter.list}
        >
          <option value="" key="0">
            الحالة
          </option>
          {List.map((list, index) => (
            <option key={"status"+ index}  value={list}>
              {list}
            </option>
          ))}
        </select>
            <SearchBox value={search} onChange={setSearch} />
                   <Pagination
        itemsCount={dreamtotranslate ? dreamtotranslate.length : 0}
        pageSize={20}
        currentPage={currentPage}
        onPageChange={ChangeCurrentpage}
    
      ></Pagination>
        <ListMsgDataTable dreams={dreampaination}></ListMsgDataTable>
            </div>
      );
}


 