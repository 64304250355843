import * as React from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export default function Book() {
    const search = useLocation().search;
    const name = new URLSearchParams(search).get("name");
    const id = new URLSearchParams(search).get("id");
    const lock = new URLSearchParams(search).get("lock");
    console.log({ name, id });
  localStorage.setItem("name", name);

  return (
    <div>
     
    </div>
  );
}
