import React from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getUserList } from '../../store/user';
import Select from './select';

function UserList({ dream, handleChange, label ,id}) {
  const dispatch = useDispatch();
  const users = useSelector(getUserList);
  //   const handleChange = (event) => {
  //     const userlocked = event.target.value;
  //   };
  return (
    <div className="m-2">
      <Select
        handleChange={handleChange}
        dream={dream}
        name={"userlock"}
        label={label}
        options={users}
      ></Select>
    </div>
  );
}

export default UserList;