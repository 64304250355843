import React from 'react';
import PropTypes from 'prop-types';
import Table from './table/table';
import Moment from "react-moment";

 
function HistoryDataTable1({ dreams }) {
  
  const columns = [
  
    
    {
      key: 1,
      path: "mo_message",
      label: "الرسالة المرسلة",
      content: (dream) => (
 <div>{dream.mo_message}</div>
      
      ),
    },
   
    {
      key: 2,
      path: "mt_reply_msg",
      label: "الرد للرسالة",
      content: (dream) => (
        <div> 

        
        {dream.mt_reply_msg}
        </div>
       
      ),
    },
   

    
  ];
  return (
    <div>
      <Table columns={columns} data={dreams} idkey="replyid" />
    </div>
  );
}

export default HistoryDataTable1;